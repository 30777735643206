.howitswork {
    position: relative;
    margin-bottom: 80px;
}

.howitswork .mainwapperHowits {
    position: static;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.howitswork .mainwapperHowits .contentblockhiw {
    width: 560px;
    margin: 0;
    padding: 0;
}

.howitswork .mainwapperHowits .contentblockhiw h2 {
    font-size: 40px;
    font-weight: 900;
    line-height: 48px;
    margin-bottom: 10px;
}

.howitswork .mainwapperHowits .contentblockhiw h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 15px;
    padding: 0 100px 0 0;
}

.howitswork .mainwapperHowits .contentblockhiw p {
    font-size: 16px;
    font-weight: 400;
    color: var(--black);
    line-height: 25px;
    margin-bottom: 40px;
    padding: 0 104px 0 0;
}

.howitswork .mainwapperHowits .contentblockhiw ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-bottom: 20px;
}

.howitswork .mainwapperHowits .contentblockhiw ul li {
    margin-right: 16px;
    flex: 1;
    max-width: 100%;
    border-radius: 30px;
    border: 1px solid #595aff;
    padding: 25px 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    position: relative;
}

.howitswork .mainwapperHowits .contentblockhiw ul li span {

    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 22px;
    font-weight: 700;
    color: #595aff;
}

.howitswork .mainwapperHowits .contentblockhiw ul li img {

    margin: 0 auto 15px;
    display: block;
}

.howitswork .mainwapperHowits .contentblockhiw ul li h3 {

    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--black);
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 20px;
    line-height: 30px;
}

.howitswork .mainwapperHowits .contentblockhiw ul li p {

    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 15px;
    line-height: 17px;
    color: #444;
    font-weight: 500;
}

.howitswork .mainwapperHowits .heroImgtwo {
    background: var(--blue);
    position: absolute;
    top: 0;
    right: 0;
    width: 46%;
    height: 560px;
    overflow: hidden;
    border-top-left-radius: 500px;
    border-bottom-left-radius: 500px;
}

.howitswork .mainwapperHowits .heroImgtwo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width:600px) {
    .howitswork .mainwapperHowits .heroImgtwo {
        display: none;
    }

    .howitswork .mainwapperHowits .contentblockhiw {
        width: 400px;
        text-align: center;
    }

    .howitswork .mainwapperHowits .contentblockhiw h3 {
        padding: 10px;
    }

    .howitswork .mainwapperHowits .contentblockhiw p {
        padding: 10px;
    }

    .howitswork .mainwapperHowits .contentblockhiw ul {
        width: 390px;
        justify-content: center;
        padding: 5px;
    }

    .howitswork .mainwapperHowits .contentblockhiw ul li {
        width: 300px !important;
        border-radius: 30px;
        padding: 20px 10px;
        flex: none;
        max-width: 600px;
        margin: 10px 0;
    }

}