.mid-content {
  padding: 20px 0px 40px;
}

.top-sectionHeading h2 {
  margin-bottom: 14px;
  font-weight: 700;
  font-size: 20px;
}

.top-sectionBanner {
  position: relative;
  z-index: 0;
  border-radius: 4px;
  height: -webkit-fill-available;
}

.top-sectionBanner img {
  border-radius: 4px;
}

.top-sectionBanner h2 {
  color: white;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
}

.top-sectionLabel {
  position: relative;
  left: 0;
  right: 0;
  width: 90%;
  margin: 0 auto;
  bottom: 515px;
  z-index: 2;
}
.top-sectionBanner div {
  color: white;
}

.blur-bg {
  width: 777px;
  height: 437px;
  top: -437px;
  position: relative;
  z-index: 1;
  background-color: black;
  opacity: 40%;
  border-radius: 4px;
}

#top-section {
  display: flex;
  height: 467px;
}

.top-sectionRow {
  border-bottom: 1px solid #eceff1;
  padding: 30px;
  margin: 30px;
}

.left-section1 {
  display: flex;
  width: 312px;
  border-bottom: 1px solid #eceff1;
  padding: 0 0 6px 0;
  margin: 0 0 6px 0;
}

.left-section1 a {
  display: flex;
  color: black;
  text-decoration: none;
}

.imgBlock {
  flex: 0 0 100px;
  height: 92px;
  margin-right: 0px;
  align-self: center;
  overflow: hidden;
}
.imgBlock img {
  height: 92px;
  object-fit: cover;
}

.textBlock {
  flex: 1;
  padding: 8px;
}

.textBlock h3 {
  font-weight: 500;
  line-height: 18px;
  font-size: 15px;
  margin-bottom: 6px;
}

/* down section */
.down-sectionRow{
  display: flex;
  justify-content: space-evenly;

}
.leftside{
  width: 900px !important;
}

.rightside{
  width: 300px;
}
.down-colheading h2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

#down-colSelect {
  background-color: #595aff;
  width: 219px;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.downBlock {
  flex: 0 0 31%;
  margin: 10px;
  width: 241px;
  height: 305px;
}
.img-holder {
  height: 190px;
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #f1f1f1;
}
.img-holder img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.downBlock a h2{
    color: black;
    text-decoration: none;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 10px;
}
.downBlock a{
    color: black;
    text-decoration: none;
   
}

.wrap-downSectionBlock{
    display: flex;
    flex-wrap: wrap;
}

/* .downside-colheading{
    margin-bottom: 40px;
} */
.downside-colheading h2{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
}

.search-submit input{
    height: 40px;
    width: 200px;
    padding: 0 10px;
    font-size: 14px;
    border: 1px solid #191b1f;
    border-radius: 4px;
    float: left;

}

.search-submit Button{
    height: 40px;
    width: 68px;
    padding: 0 10px;
    font-size: 14px;
    border: 1px solid #191b1f;
    border-radius: 4px;
    float: right;
    

}
.search-submit{
    width: 300px;
    height: 40px;
    margin-bottom: 40px;    
}

.downside-section{
    width: 300px;
    height: 270px;
    margin-bottom: 40px;
}
.downside-sectionBlock{
    background-color: #595aff;
    width: 300px;
    height: 270px; 
    border-radius: 4px; 
    padding: 20px;
}

.downside-sectionBlock h2{
    text-align: center;
    font-size: 30px;
    position: relative;
    line-height: 34px;
    color: white;
    padding-top: 15px;
    margin: 0px;
    font-weight: 700;
    text-transform: none;
    /* display: block; */
}

.downside-sectionBlock hr{
    width: 80px;
    height: 2px;
    background: #fff;
    display: block;
    margin: 20px auto;
    
}

.downside-sectionBlock p{
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: white;
    margin-bottom: 20px;
    
}

.downside-sectionBlock span{
    color: #595aff;
    background-color: #fff;
    width: 100%;
    border-radius: 30px;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    padding: 10px 0;
    display: block;
    
}

.downside-heading2 h2{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
}

.ebookBlock{
    width: 300px;
    height: 442px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 10px;
}

.ebookBlock a{
   display: flex;
   flex-direction: column;
   text-decoration: none;
}

.ebook-cover{
    margin-bottom: 10px;
}
.ebook-text{
    width: 268px;
    height: 40px;
    display: flex;
}

/* .ebook-text h3{
    flex: 1;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-decoration: none;
    color: black;
    margin-bottom: 15px;
    margin-right: 20px;
    text-align: left;
    
} */

.ebook-text h3{
    flex: 1;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    text-decoration: none;
    color: black;
    margin-bottom: 0;
    
    margin-right: 20px;
    text-align: left;
    
}

.ebook-text span{
    
    font-size: 14px;
    background-color: #00d463;
    border-radius: 4px;
    color: white;
    font-weight: 700;
    text-decoration: none;
    padding: 4px 10px;
    height: 32px;
   
   
    
}

.ebook-downSection{
  margin-bottom: 15px;
  width: 300px;
  height: 442px;
}

.downside-button a{
  text-align: center;
  font-size: 16px;font-weight: 700;
  border-radius: 30px;
  margin: 10px 0 0 70px;
  padding: 8px 15px;
  background-color: #595aff;
  color: white;
  text-decoration: none;
  display: table;
  
}
.down-pageholder{
  margin: 50px auto;
  display: flex;
  justify-content: center;
}

.down-pageholder Pagination{
   background-color: #00d463;
}
 
 