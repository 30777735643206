.homecity {
    margin-bottom: 40px;
    position: relative;
}

.homecity .homecitymainwapper {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
}

.homecity h2 {
    text-align: center;
    color: black;
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
    margin-bottom: 20px;

}

.homecity p {
    font-size: 20px;
    color: black;
    font-weight: 400;
    padding: 0 130px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 46px;
}

.homecity .innercityholder {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.homecity .innercityholder .block {
    flex: 0 0 145px;
    max-width: 100%;
    margin: 0 15px 30px 0;
    padding: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    transition: all 0.5s;

}

.homecity .innercityholder .block:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    transform: translate(0, -10px);
}

.homecity .innercityholder .block a {
    background: white;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 0;
    box-shadow: 0 0 20px rgb(0 0 0 / 5%);
    border: 1px solid #f4f4f4;
    text-decoration: none;
    color: #454545;
}

.homecity .innercityholder .block img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-width: 100%;
    /* transition: transform .5s, filter 1.05s ease-in-out; */
}

/* .homecity .innercityholder .block:hover img {
    transform: scale(1.05);
} */

.homecity .innercityholder .block .h3 {
    margin-top: auto;
    font-size: 16px;
    color: #454545;
    text-decoration: none;
    text-transform: uppercase;
    padding: 10px 0;
}

@media screen and (max-width:600px) {
    .footer{
        display: none;
    }
    .homecity p {
        padding: 4px;
    }
}