.topdiv-infulencer-detail {
    background-color: #595aff;
    color: white;
    height: 350px;
}

.infulencer-detail-main {
    margin-top: -18%;
    display: flex;
    justify-content: space-evenly;
}

.influencer-bio {
    color: white;
    margin-left: 100px;
}

.influencer-bio h1 {
    font-weight: bold;
    font-size: 62px;
}

.detail-div {
    margin-left: 100px;
    display: flex;
    padding: 10px 0px 10px 20px;
    border-radius: 8px;
    background-color: #ffffff;
    width: 700px;
    box-shadow: 1px 1px 4px rgb(187, 201, 223);

}

.detail-tab {
    padding: 6px 25px;
    background-color: #ffffff;
    border: none;
    margin: 0 0 3px 10px;
    font-size: 18px;
    font-weight: 500;
    color: rgb(124, 123, 123);
    border-bottom: 4px solid rgb(255, 255, 255);
    text-align: start;
    font-size: 18px;

}

.detail-tab:hover {
    border-bottom: 4px solid #595aff;
    color: #000000;
}

.detailtab-div{
    width: 700px;
    border-bottom: 1px solid #d3c9c9;
    margin: auto;
    padding:30px 10px;

}
.detailtab-div h3{
    margin-bottom: 20px;
}
.detailtab-div p{
    margin-bottom: 3px;
}
.socialmedia-div{
margin:14px 30px 14px 0;
display: flex;
justify-content: center;
align-items: center;
}

.socialmedia-div p{
    margin-left: 8px;
    font-weight: 500;
}
.worksample{
    border-left: 6px solid #595aff;
    padding: 30px;
    border-radius: 6px;
    margin-top: 30px !important;
}
.worksample div button{
    border-radius: 20px;
    padding: 0px 10px;
    margin-left: 10px;
    background-color: white;
    color: #595aff;
    border: 1px solid #595aff; 
}


.worksample div button:hover{
    background-color: #595aff;
    color: white;
    border: 1px solid #595aff;

}
.worksample h6{
    font-weight: 700;
}
.worksample p{
    font-size: 14px;
}
.instagram-statistics-div{
    margin-left: 90px;
}
.instagram-statistics-div div h2{
    margin: 40px 10px;
}
.instagram-div ul{
    display: flex;
    justify-content: space-evenly;
    margin: 30px 0;
    padding: 20px 0;
    border-radius: 6px;
    width: 700px;
    box-shadow: 1px 1px 4px rgb(187, 201, 223);


}
.instagram-div ul li,.instagram-Statistical-diff-div ul li{
    list-style: none !important;
    text-align: center;

}
.instagram-div ul li:not(:last-child),.instagram-Statistical-diff-div ul li:not(:last-child){
    border-right: 1px solid lightgrey;
    padding-right: 40px;

}

.instagram-div ul li p,.instagram-Statistical-diff-div ul li p{
    margin: 6px 0 2px 0;
    font-weight: bold;
    font-size: 32px;
    color: #595aff;
}
.Statistical-diff{
    box-shadow: 1px 1px 4px rgb(187, 201, 223);
    width: 700px;

}
.Statistical-diff h6{
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
    margin: 0 40px 0 30px;
    padding-top: 20px;
}
.instagram-Statistical-diff-div ul{
    display: flex;
    justify-content: space-evenly;
    margin: 15px 0;
    padding: 0 0 15px 0;
    border-radius: 6px;
    width: 700px;
}

.hastagbtn-div{
    box-shadow: 1px 1px 4px rgb(187, 201, 223);
    width: 700px;
    margin-left: 90px;
    padding:0 10px 0 20px;
}
.hastagbtn-div h5{
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
    margin: 0 40px 0 30px;
    padding-top: 20px;

}
.all-btn-div{
    display: flex;
    flex-wrap: wrap;
    padding: 4px 0 20px 0;
}

.all-btn-div .btn {
    margin: 5px;
    color: #595aff;
    border: 1px solid #595aff;
    font-weight: 600;
    border-radius: 30px;
    padding: 5px 15px;
    font-size: 12px;
}


.all-btn-div .btn:hover {
    background-color: #595aff;
    color: white;
    border: 1px solid #595aff;
}
.tablediv,.recent-post-div{
    box-shadow: 1px 1px 4px rgb(187, 201, 223);
    width: 700px;
    margin:20px 0 0 90px;
    padding:0 10px 20px 20px;
}
.tablediv h5,.recent-post-div h5{
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
    margin: 0 10px 20px 0px !important;
    padding-top: 20px;

}

.tablediv table{
    text-align: center;
}
.tablediv .headertable{
    background-color: #595aff;
}

.tablediv .headertable th{
    color: white;
    font-weight: bold;
 }
 .post-box{
    width: 170px;
    box-shadow: 1px 1px 4px rgb(187, 201, 223);
    border-radius: 4px;
    margin: 20px;

 }
 .post-box div img{
    width: 170px;
    height: 200px;
    object-fit: cover;
    border-radius: 4px 4px 0 0;
 }
 .post-like-cmnt{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 5px 6px;
 }
 .post-like-cmnt p {
    margin-bottom: 3px;
 }
.post-like-cmnt p .icon{
    margin-right: 2px;
    margin-bottom: 2px;
}


.cardbox {
    width: 300px;
    border-radius: 6px;
    margin: 5px;
}

.cardbox .cardimg-div1 {
    background-image: url('https://www.influglue.com/files/profileimages/9d297f2de472f062ffc0c65bbe5e0c0a.jpg');
}

.cardbox .cardimg-div2 {
    background-image: url('https://www.influglue.com/files/profileimages/f40ff3d1f4053c46a06296f58ffdd00f.jpg');
}

.cardbox .cardimg-div3 {
    background-image: url('https://www.influglue.com/files/profileimages/13b630cdcdfce3862cc06864bea09346.jpg');
}

.cardbox .cardimg-div4 {
    background-image: url('https://www.influglue.com/files/profileimages/fa9366f17a9d5e9cd84ac03c570871e7.jpg');
}

.cardbox .cardimg-div5 {
    background-image: url('https://www.influglue.com/files/profileimages/dd118376f6dcb6f27b44d7e784c504ee.jpg');
}

.cardbox .cardimg-div6 {
    background-image: url('https://www.influglue.com/files/profileimages/86178796179577a5d2a99dc9e5b06bd6.jpg');
}

.cardbox .cardimg-div1,
.cardimg-div2,
.cardimg-div3,
.cardimg-div4,
.cardimg-div5,
.cardimg-div6 {
    height: 300px;
    width: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 6px 6px 0 0;

}

.cardbox .cardimg-div1 img,
.cardimg-div2 img,
.cardimg-div3 img,
.cardimg-div4 img,
.cardimg-div5 img,
.cardimg-div6 img {
    width: 30px;
    margin: 10px 0 0 10px;
}

.cardbox .cardimg-div1 p span,
.cardimg-div2 p span,
.cardimg-div3 p span,
.cardimg-div4 p span,
.cardimg-div5 p span,
.cardimg-div6 p span {
    color: rgb(221, 26, 26) !important;
}

.cardbox .cardcontent-div {
    text-align: center;
    background-color: rgb(39, 102, 57);
    color: white;
    border-radius: 0 0 6px 6px;
    height: 300px;
}

.cardbox .cardcontent-div h6 {
    font-weight: bold;
    padding: 10px 0 3px 0;
    margin-bottom: 3px;
}

.cardbox .cardcontent-div div button {
    margin: 5px;
    border-radius: 20px;
    color: white;
    border: 1px solid white;
}

.cardbox .cardcontent-div div ul {
    list-style: none;
    display: flex;
    padding-left: 0;
    justify-content: center;
    padding: 30px 0;
}

.cardbox .cardcontent-div div ul li p {
    margin-bottom: 2px;
    font-weight: bold;
}

.cardbox .cardcontent-div div ul li span {
    font-size: 14px;
}

.cardbox .cardcontent-div div ul li {
    border-top: 1px solid grey;
    padding: 10px 15px;
}


.rightmain-div{
    position: relative;
}

.rightimg-div {
    border-radius: 12px;
    width: 380px;
    box-shadow: 1px 1px 4px rgb(187, 201, 223);
    position: absolute;
    position: sticky;
    top: 30px;
    margin-left: 50px;
}

.rightimg-div img {
    border-radius: 12px;
    width: 380px;
    height: 450px;
    border: 18px solid white;
    object-fit: cover;

}

.rightimg-div .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
}

.rightimg-div .btn button {
    background-color: #595aff;
    color: white;
    border-radius: 30px;
    padding: 8px 50px;
    border: 1px solid #595aff;
    font-weight: 600;

}

.rightimg-div .btn button:hover {
    background-color: white;
    color: #595aff;
    border: 1px solid #595aff;
    border-radius: 30px;

}

@media screen and (max-width:600px) {
    .infulencer-detail-main {
        display: block;
        margin-top: -70%;
    }
    .rightmain-div{
        display: flex;
        justify-content: center;
    }
    .rightimg-div {
        margin-left: 0px;
    }
    .influencer-bio {
        color: black;
        text-align: center;
        margin: 20px;
    }
    .detail-div {
        width: 400px;
        margin-left: 10px;
        padding: 5px;
        display: flex;
        justify-content: space-evenly;
        
    }
    .detail-tab {
        padding: 10px;
    }
    .detailtab-div{
        width: 400px;

    }
    .worksample p{
        width: 300px !important;
    }
    .worksample div{
        display: block !important;
    }
    .instagram-statistics-div{
        margin-left: 10px;
        text-align: center;
    }

    .instagram-div ul{
        width: 400px;
        display: flex;
        justify-content: space-evenly;
    }
    .instagram-div ul li:not(:last-child),.instagram-Statistical-diff-div ul li:not(:last-child){
        padding-right: 15px;
    }
    .instagram-div ul li p,.instagram-Statistical-diff-div ul li p{
        font-size: 22px;
    }
    .instagram-div ul li span{
        font-size: 14px;
    }
    .Statistical-diff{
        width: 400px;
    }
    .instagram-Statistical-diff-div ul{
        width: 400px;
        display: flex; 
        justify-content: space-evenly;
    }
    .instagram-Statistical-diff-div ul li span{
        font-size: 14px;
    }
    .hastagbtn-div{
        width: 400px;
        margin-left: 10px;
       padding:0 10px 0 10px;
    }
    .tablediv,.recent-post-div{
        width: 400px;
        overflow: scroll;
        margin-left: 10px;
        padding:10px;
    }
    .recent-post-div .abc{
        justify-content: space-evenly;
    }
    .post-box{
        margin: 4px;
    }
}