.influencer-top-div {
    height: 450px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.influencer-top-div div {
    color: white;
    width: 600px;
    margin-right: 20px;
}

.influencer-top-div div h1 {
    font-size: 60px;
    font-weight: bolder;
    margin-bottom: 30px;
}

.influencer-top-div div h5 {
    line-height: 30px;
}

.homectgry {
    background: #f9f9f9;
    padding: 70px 0 55px;
    margin-bottom: 30px;
}

.homectgry .homectgMainwapper {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.homectgry h2 {
    text-align: center;
    color: var(--black);
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
    margin-bottom: 20px
}

.homectgry p {
    font-size: 20px;
    color: var(--black);
    font-weight: 400;
    padding: 0 100px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 46px;
}

.homectgry .inner-ctg {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.homectgry .inner-ctg .block {
    flex: 0 0 145px;
    max-width: 100%;
    margin: 0 15px 30px 0;
    padding: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    transition: all 0.5s;
    border-radius: 8px;

}

.homectgry .inner-ctg .block:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    transform: translate(0, -10px);
}



.homectgry .inner-ctg .block a {
    background: var(--white);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 25px 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .05);
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

.homectgry .inner-ctg .block img {
    margin: 0 auto 15px;
    width: 65px;
    max-width: 100%;
}

.homectgry .inner-ctg .block h6 {
    margin-top: auto;
    font-size: 14px;
    font-weight: bold;
    color: #454545;
    text-decoration: none;
    text-transform: uppercase;
}

.filterdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f3f5;
    padding: 10px 20px;
}

.filterdiv img {
    width: 20px;
    height: 20px;
}

.leftsidediv {
    background-color: #f9fdff;
    border-right: 1px solid #e8e9eb;
    max-width: 220px;
    margin-top: 20px;
}

.city-div {
    font-weight: 500;
    margin:30px 10px;
}

.city-div h5 {
    margin: 10px 0;
}
.city-div .clickdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 4px;
    background-color: white;
    padding: 8px;
    cursor: pointer;
}
.city-div h6 {
    margin: 2px 0;
    color: #454545;
    font-weight: bold;
    font-size: 16px;

}

.city-div ul {
    line-height: 25px;
    padding:10px;

}

.city-div ul li {
    font-size: 14px;
}

.rightsidediv {
    width: 1050px;
    margin-left: 10px;
}

.card-box {
    width: 300px;
    border-radius: 6px;
    margin: 20px;
}

.card-box .card-img-div1 {
    background-image: url('https://www.influglue.com/files/profileimages/9d297f2de472f062ffc0c65bbe5e0c0a.jpg');
}

.card-box .card-img-div2 {
    background-image: url('https://www.influglue.com/files/profileimages/f40ff3d1f4053c46a06296f58ffdd00f.jpg');
}

.card-box .card-img-div3 {
    background-image: url('https://www.influglue.com/files/profileimages/13b630cdcdfce3862cc06864bea09346.jpg');
}

.card-box .card-img-div4 {
    background-image: url('https://www.influglue.com/files/profileimages/fa9366f17a9d5e9cd84ac03c570871e7.jpg');
}

.card-box .card-img-div5 {
    background-image: url('https://www.influglue.com/files/profileimages/dd118376f6dcb6f27b44d7e784c504ee.jpg');
}

.card-box .card-img-div6 {
    background-image: url('https://www.influglue.com/files/profileimages/86178796179577a5d2a99dc9e5b06bd6.jpg');
}

.card-box .card-img-div1,
.card-img-div2,
.card-img-div3,
.card-img-div4,
.card-img-div5,
.card-img-div6 {
    height: 300px;
    width: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 6px 6px 0 0;

}

.card-box .card-img-div1 img,
.card-img-div2 img,
.card-img-div3 img,
.card-img-div4 img,
.card-img-div5 img,
.card-img-div6 img {
    width: 30px;
    margin: 10px 0 0 10px;
}

.card-box .card-img-div1 p span,
.card-img-div2 p span,
.card-img-div3 p span,
.card-img-div4 p span,
.card-img-div5 p span,
.card-img-div6 p span {
    color: rgb(221, 26, 26) !important;
}

.card-box .card-content-div {
    text-align: center;
    background-color: rgb(39, 102, 57);
    color: white;
    border-radius: 0 0 6px 6px;
    height: 300px;
}

.card-box .card-content-div h6 {
    font-weight: bold;
    padding: 10px 0 3px 0;
    margin-bottom: 3px;
}

.card-box .card-content-div div button {
    margin: 5px;
    border-radius: 20px;
    color: white;
    border: 1px solid white;
}

.card-box .card-content-div div ul {
    list-style: none;
    display: flex;
    padding-left: 0;
    justify-content: center;
    padding: 30px 0;
}

.card-box .card-content-div div ul li p {
    margin-bottom: 2px;
    font-weight: bold;
}

.card-box .card-content-div div ul li span {
    font-size: 14px;
}

.card-box .card-content-div div ul li {
    border-top: 1px solid grey;
    padding: 10px 15px;
}

.btn-div-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.btn-div-bottom button {
    background-color: green;
    color: white;
    border-radius: 30px;
    padding: 12px 50px;
    border: 1px solid green;
    font-size: large;
    font-weight: 600;

}

.btn-div-bottom button:hover {
    background-color: white;
    color: green;
    border: 1px solid green;
    border-radius: 30px;

}

@media screen and (max-width:600px) {
    .influencer-top-div div h1 {
        font-size: 40px;
        text-align: center;
    }

    .influencer-top-div div h5 {
        text-align: center;
        margin: 0 30px;
    }

    .homectgry h2 {
        font-size: 24px;
    }

    .homectgry p {
        padding: 20px;
    }

    .leftsidediv {
        width: 210px !important;
        margin-left: 27px;
        padding-left: 0;
    }

    .filterdiv {
        padding: 10px 5px;
        display: flex;
        align-items: center;
    }

    .filterdiv h5 {
        font-size: 14px;
        font-weight: bold;
    }

    .filterdiv img {
        width: 15px;
        height: 15px;
    }

    .city-div ul {
        padding-left: 2px;
    }

    .rightsidediv {
        margin: 4px;
    }

    .rightsidediv,
    .card-box {
        margin: 10px 3px;
    }

    .card-box .card-img-div1,
    .card-img-div2,
    .card-img-div3,
    .card-img-div4,
    .card-img-div5,
    .card-img-div6 {
        width: 280px;
    }
    .card-box .card-content-div {
        width: 280px;
    }

    .btn-div-bottom {
        margin-left: 20%;
    }

    .btn-div-bottom button {
        padding: 10px 20px;
        font-size: medium;
        font-weight: 500;
    }

}