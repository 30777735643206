.mainloginholder {
    background: #f1f1f1;
    padding: 60px 0;
    display: block;
}

.mainloginholder .mainwappermainloginholder {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;

}

.landingSingupholder h1 {
    text-align: center;
    font-size: 48px;
    line-height: 50px;
    margin-bottom: 60px;
    display: block;
    font-weight: 900;
    color: black;
}

.landingSingupholder ul {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
}

.landingSingupholder ul .signuplist {
    text-align: center;
    display: block;

}

.landingSingupholder ul.signuplist li {
    margin-right: 50px;
    width: 36%;
    background: white;
    border: 1px solid white;
    display: inline-block;
    padding: 40px 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    transition: all 0.5s;


}

.landingSingupholder ul.signuplist li:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    transform: rotate();
    border: 1px solid #595aff;
}

/* .homectgry .inner-ctg .block {
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);

} */


.signuplist li a {
    text-decoration: none;
}

.iconHolder {
    display: flex;
    width: 130px;
    height: 130px;
    border: 1px solid#595aff;
    margin: 0 auto 30px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.textblock {
    align-items: center;
    display: flex;
    justify-content: center;
}

.textblock h3 {
    color: #595aff;
    font-size: 25px;
    display: block;
    margin-bottom: 20px;
    font-weight: 700;


}