.influencermarketingblock {
    margin-top: 70px;
    padding: 70px 0;
}

.influencermarketingblock .back {
    background-color: #595aff;
    width: 100%;
    height: 440px;


}

.mainwapperInfluncermarketing {
    padding-top: 60px;
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.influencermarketingblock h2 {
    text-align: center;
    color: white;
    font-size: 60px;
    font-weight: 900;
    text-shadow: 0 2px 5px rgba(0, 0, 0, .2);
    margin: 0 0 20px 0;
    line-height: 60px;
}

.influencermarketingblock p {
    margin: 0 auto;
    font-size: 20px;
    line-height: 30px;
    color: white;
    text-align: center;
    font-weight: 400;
    margin-bottom: 60px;
    padding: 0 200px;
}

.influencermarketingblock ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 45px;
}

.influencermarketingblock ul li {
    margin-right: 28px;
    background: white;
    flex: 1;
    max-width: 100%;
    border-radius: 30px;
    border: 1px solid #595aff;
    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.influencermarketingblock ul li h3 {
    font-weight: 700;
    font-size: 27px;
    color: black;
    line-height: 27px;
    margin: 0 0 15px 0;
}

.influencermarketingblock ul li p {
    text-align: center;
    font-size: 15px;
    color: #545454;
    padding: 0;
    margin: 0;
    line-height: 20px;
}

.ulk {
    margin-top: -170px;
}

.influencermarketingblock a {
    background: #595aff;
    border: 2px solid #595aff;
    width: 520px;
    border-radius: 50px;
    font-size: 26px;
    font-weight: 700;
    color: white;
    margin: 0 auto;
    display: block;
    text-align: center;
    line-height: 60px;
    transition: all 0.5s;
    text-decoration: none;
}
.small-circle img{
    width: 60px;
    height: 60px;
}

@media screen and (max-width:600px) {
   .mainwapperInfluncermarketing{
    padding: 5px;
   }
   .influencermarketingblock h2 {
    font-size: 36px;
    line-height: 38px;
    padding:50px 0 20px 0;
}
.influencermarketingblock p {
    margin-bottom: 20px;
    padding: 0 10px;
    font-size: 16px;
}
.ulk{
    margin-top: -130px !important;
}
.ulk ul {
    padding: 5px;
}
.ulk ul li{
    margin: 10px 5px;
}
.ulk a{
    width: 350px;
    border-radius: 30px;
    font-size: 16px;
    line-height: 30px;
    transition: all 0.5s;
    text-decoration: none;
    padding: 10px 20px;
}
}