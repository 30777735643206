.techblock {
    margin: 90px auto;
    /* position: relative; */
}

.techblock .mainwappertechblock {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    /* position: relative; */
}

.techblock .mainwappertechblock .innerweb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.techblock .innerweb .contentblock {
    flex: 0 0 416px;
    max-width: 100%;
    margin: 0 60px 0 0;
    padding: 0 0 0 28px;
    /* position: relative; */
}

.techblock .innerweb .contentblock h2 {
    font-size: 40px;
    line-height: 45px;
    color: black;
    font-weight: 900;
    margin: 0 0 30px 0;
}

.techblock .innerweb .contentblock p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.techblock .innerweb .card-block {
    flex: 1;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.techblock .innerweb .card-block .block {
    margin-right: 24px;
    background: white;
    flex: 1;
    max-width: 100%;
    border-radius: 30px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 14px;
}

.techblock .innerweb .card-block .block .circle-block {
    background: #595aff;
    width: 130px;
    height: 130px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px 0;
}

.techblock .innerweb .card-block .block h3 {
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    color: black;
    margin-bottom: 20px;

}

.techblock .innerweb .card-block .block p {
    font-size: 15px;
    text-align: center;
    color: #545454;
    line-height: 22px;
    margin-bottom: 20px;

}

.techblock .innerweb .card-block .block a {
    background: #595aff;
    width: 118px;
    height: 36px;
    border-radius: 500px;
    color: white;
    text-align: center;
    line-height: 36px;
    font-weight: 600;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

/* blocks */
.techblock .innerweb .card-block {
    flex: 1;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.techblock .innerweb .card-block .blocks {
    margin-right: 24px;
    background: #595aff;
    flex: 1;
    max-width: 100%;
    border-radius: 30px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 14px;
}

.techblock .innerweb .card-block .blocks .circle-blocks {
    background: white;
    width: 130px;
    height: 130px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px 0;
}

.techblock .innerweb .card-block .blocks h3 {
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    color: white;
    margin-bottom: 20px;

}

.techblock .innerweb .card-block .blocks p {
    font-size: 15px;
    text-align: center;
    color: white;
    line-height: 22px;
    margin-bottom: 20px;

}

.techblock .innerweb .card-block .blocks a {
    background: white;
    width: 118px;
    height: 36px;
    border-radius: 500px;
    color: #595aff;
    text-align: center;
    line-height: 36px;
    font-weight: 700 !important;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

/* Marketing Inf */
.mainwappermark {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;

}

.marketing-holder {
    padding: 0 56px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.marketing-holder .imgholder {
    flex: 0 0 401px;
    max-width: 100%;
    margin: 0 58px 0 0;
}

.marketing-holder .imgholder img{
    width: 400px;
    height: 450px;
}
.marketing-holder .content {
    flex: 1;
    max-width: 100%;
}

.marketing-holder .content h2 {
    font-size: 40px;
    font-weight: 900;
    line-height: 46px;
    color: black;
    margin: 0 0 20px 0;
}

.marketing-holder .content p {
    font-size: 16px;
    line-height: 24px;
}

@media screen and (max-width:600px) {
    .techblock{
        text-align: center;
    }
    .techblock .innerweb .contentblock {
    margin: 5px;
    padding:8px;

    }
    .techblock .innerweb .card-block {
        display: block;
    }
    .techblock .innerweb .card-block .block {
        margin-bottom: 20px;
    }
    .marketing-holder {
        padding: 5px;
    }
    .marketing-holder .imgholder {
        margin: 5px;
        display: flex; 
        justify-content: center;
    }
    .marketing-holder .imgholder img{
        width: 300px;
        height: 350px;
    }
    .marketing-holder .content {
        text-align: center;
        margin-top: 60px;
    }
}