.banner {
    display: block;
    position: relative;
    padding: 110px 0;
    overflow: hidden;
}

.banner .bannerinnerblock {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mainWapperClearFix {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
    zoom: 1;
}

.banner .textBlock {
    width: 52%;
    float: right;

}

.white {
    color: white;
}

.banner .textBlock h2 {
    font-size: 60px;
    line-height: 70px;
    display: block;
    margin-bottom: 20px;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 0 0 4px rgba(0, 0, 0, .5);
}

.banner .textBlock p {
    padding: 0;
    margin-bottom: 30px;
    display: block;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    text-shadow: 0 0 2px rgba(0, 0, 0, .5);

}

.howitstepsblock {
    padding: 40px 0;
    display: block;
}

.mainwapperhowit {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.howitiblock h2 {
    color: #3b373a;
    text-align-last: center;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 80px;
    display: block;
}

.howitprogressbar {
    margin: 0 0 80px 0;
    padding: 0;
    counter-reset: step;
    text-align: center;
}

ul {
    list-style: none;
}

.activeBorder {
    color: #595aff;
}

.howitprogressbar li .active {
    color: #595aff;
    font-weight: 600;

}

.howitprogressbar li {
    list-style-type: none;
    width: 24%;
    font-size: 18px;
    position: relative;
    text-align: center;
    /* color: #535353; */
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
}

.howitprogressbar li .arrow1 {
    background: url("https://www.influglue.com/public/frontend/images/arrow.png");
    background-repeat: no-repeat;
    width: 14px;
    height: 24px;
    position: absolute;
    left: 270px;
    top: 19px;
}

.howitprogressbar li .arrow2 {
    background: url("https://www.influglue.com/public/frontend/images/arrow.png");
    background-repeat: no-repeat;
    width: 14px;
    height: 24px;
    position: absolute;
    left: 270px;
    top: 19px;
}

.howitprogressbar li .arrow3 {
    background: url("https://www.influglue.com/public/frontend/images/arrow.png");
    background-repeat: no-repeat;
    width: 14px;
    height: 24px;
    position: absolute;
    left: 270px;
    top: 19px;
}

.howitprogressbar li .arrow {
    background: url("https://www.influglue.com/public/frontend/images/arrow.png");
    background-repeat: no-repeat;
    width: 14px;
    height: 24px;
    position: absolute;
    left: 270px;
    top: 19px;
}

.howitprogressbar li.active::before {
    border-color: #595aff;
    background: #fff;
    color: #595aff;
}

.howitprogressbar li::before {
    width: 60px;
    height: 60px;
    content: counter(step);
    counter-increment: step;
    line-height: 60px;
    border: 2px solid #dadada;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: #fff;
    color: #595aff;
    font-size: 24px;
}

.howitprogressbar li .active .activeBorder li ::after {
    background-color: #595aff;

}

.howitprogressbar li ::after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    top: 30px;
    left: -50%;
    z-index: -1;
    background-color: #dadada;
}

.howitiblock a.bluebtn {
    width: 350px;
    margin: 0 auto;
    height: 56px;
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
    text-align: center;
    line-height: 54px;
    outline: none;
    cursor: pointer;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    text-decoration: none;
}

.howitstepsblock hr {
    width: 100%;
    height: 1px;
    background: #d2d2d2;
    border: 0;
    margin: 90px auto;
    display: block;
}

.twohow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.twohow .imgblock {
    flex: 0 0 42%;
    margin-right: 95px;
}

.twohow .influcontent {
    flex: 1;
}

.twohow .influcontent h2 {
    text-align: left;
    font-size: 50px;
    line-height: 60px;
    color: #3b373a;
    display: block;
    margin-bottom: 30px;
}

.twohow .influcontent ul .dotsitem {
    overflow: hidden;
    margin-bottom: 25px;
}

.twohow .influcontent ul .dotsitem li {
    margin-right: 20px;
    float: left;
    font-size: 20px;
    position: relative;
    padding-left: 20px;
    font-weight: 600;
}

.dotsitem li ::after {
    background: #454545;
    position: absolute;
    left: 0;
    top: 7px;
    width: 10px;
    height: 10px;
    content: "";
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.listhowit {
    list-style: disc;
    float: left;
    font-size: 20px;
    position: relative;
    padding-left: 20px;
    padding-right: 53px;
    font-weight: 600;

}

.dotsitem {
    display: flex;
    overflow: hidden;
    margin-bottom: 25px;
}

.influcontent p {
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 26px;
}

.pointsnumb {
    list-style: auto;
    counter-reset: my-awesome-counter;
    display: block;
    margin-bottom: 40px;
}

.pintnbr1 {
    margin: 0 0 15px 0;
    font-weight: 400;
    counter-increment: my-awesome-counter;
    padding-left: 6px;
    position: relative;
    color: #454545;
}

.pintnbr2 {
    margin: 0 0 15px 0;
    font-weight: 400;
    counter-increment: my-awesome-counter;
    padding-left: 6px;
    position: relative;
    color: #454545;
}

.pintnbr3 {
    margin: 0 0 15px 0;
    font-weight: 400;
    counter-increment: my-awesome-counter;
    padding-left: 6px;
    position: relative;
    color: #454545;
}

.pintnbr1 li :before {
    content: counter(my-awesome-counter) '.';
    color: #595aff;
    font-weight: 700;
    left: 0;
    top: 0;
    position: absolute;
    text-align: left;
    font-size: 18px;
}

.twohow .influcontent a.bluebtn {
    width: 335px;
    height: 56px;
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
    text-align: center;
    line-height: 54px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    outline: none;
    cursor: pointer;
    background: #595aff;
    border: 1px solid #595aff;
    text-decoration: none;
}