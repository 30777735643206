.homectgry {
    background: #f9f9f9;
    padding: 70px 0 55px;
    margin-bottom: 70px;
}

.homectgry .homectgMainwapper {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.homectgry h2 {
    text-align: center;
    color: var(--black);
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
    margin-bottom: 20px
}

.homectgry p {
    font-size: 20px;
    color: var(--black);
    font-weight: 400;
    padding: 0 130px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 46px;
}

.homectgry .inner-ctg {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.homectgry .inner-ctg .block {
    flex: 0 0 145px;
    max-width: 100%;
    margin: 0 15px 30px 0;
    padding: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    transition: all 0.5s;

}

.homectgry .inner-ctg .block:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    transform: translate(0, -10px);
}

.homectgry .inner-ctg .block a {
    background: var(--white);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 25px 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .05);
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

.homectgry .inner-ctg .block img {
    margin: 0 auto 15px;
    width: 65px;
    max-width: 100%;
}

.homectgry .inner-ctg .block h3 {
    margin-top: auto;
    font-size: 15px;
    color: #454545;
    text-decoration: none;
    text-transform: uppercase;
}