.instagramBlock {
    padding: 70px 0;
}

.mAinwapperinstagram {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;

}

.instagramBlock h2 {
    text-align: center;
    color: black;
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
    margin-bottom: 20px;
}

.instagramBlock p {
    font-size: 20px;
    color: black;
    font-weight: 400;
    padding: 0 160px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 46px;
}

.cardsInsta {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.cardsInsta li {
    margin: 0 46px 61px 0;
    flex: 0 0 173px;
    max-width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    transition: all 0.5s;

}

.cardsInsta li :hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    transform: translate(0, -10px);


}


.cardsInsta li a {
    text-decoration: none;
    outline: none;
    background: #595aff;
    width: 100%;
    border-radius: 30px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, .05);
    position: relative;
}

.cardsInsta li a .circle {
    background: white;
    width: 77px;
    height: 77px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    border: 2px solid #595aff;
    transition: all 0.5s;
}

.cardsInsta li a .circle img {
    width: 50px;
    height: 50px;
}

.cardsInsta li a h4 {
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
}

.spninfInsta {
    display: block;
    text-transform: none;
    font-size: 15px;
    font-weight: 400;
    color: white;
}

.bigbtnInsta {
    background: white;
    border: 2px solid #595aff;
    width: 520px;
    border-radius: 50px;
    font-size: 26px;
    font-weight: 700;
    color: #595aff;
    margin: 50px auto 0;
    display: block;
    text-align: center;
    line-height: 60px;
    transition: all 0.5s;
    text-decoration: none;
    transition: ease-in-out;

}

.bigbtnInsta :hover {
    background-color: #595aff;
    color: white;
}
@media screen and (max-width:600px) {
    .instagramBlock p {
        padding: 5px;
    }
    .cardsInsta{
        display: flex;
        justify-content: space-evenly;
        margin: 5px;
        padding: 5px;
    }
    
.cardsInsta li {
    margin:5px;
}
.bigbtnInsta {
    width: 350px;
    border-radius: 30px;
    font-size: 16px;
    line-height: 50px;
}
}