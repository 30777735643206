.whyguestpostblock {
    padding: 60px 0 30px 0;
    background: #fafafa
}

.whyguestpostblock .mainwapperwhyguestpost {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.whyguestpostblock .WhyGuesttitleBlock {
    margin-bottom: 50px;
}

.whyguestpostblock h2 {
    text-align: center;
    font-weight: 900;
    color: black;
    margin-bottom: 20px;
    font-size: 40px;
}

.whyguestpostblock p {
    font-size: 20px;
    color: black;
    font-weight: 400;
    padding: 0 160px;
    line-height: 30px;
    text-align: center;
}

.innertwoblocksec {
    margin-right: -50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.innertwoblocksec .block {
    background: white;
    margin: 0 30px 25px 0;
    padding: 30px 20px;
    border-radius: 30px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    border: 1px solid #595aff;
    flex: 0 0 540px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.innertwoblocksec .block .icon-holder {
    background: #595aff;
    width: 89px;
    height: 89px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 0;
    border-radius: 100%;
}

.innertwoblocksec .block .icon-holder {
    width: 74px;
    max-width: 100%;
}

.innertwoblocksec .block .content {
    flex: 1;
    max-width: 100%;
}

.innertwoblocksec .block .content h3 {
    text-align: left;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 12px;
}

.innertwoblocksec .block .content p {
    padding: 0;
    font-size: 16px;
    color: #4c4c4c;
    line-height: 22px;
    text-align: left;
    margin: 0;
    font-weight: 400;
}