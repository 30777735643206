.mainSignup_holder{
    background-color: #f1f1f1;
    padding-top: 80px;
    text-align: center;
}
.mainWrapper{
    max-width: 1450px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-evenly;
    position: relative;

}
.signupForm{
    background-color: #fff;
    width: 36%;
    border-radius: 6px;
    display: inline-block;
    box-shadow: 0 0 20px rgb(0 0 0 8%);
    margin-bottom: 40px;
    vertical-align: top;
}
.signupForm h2{
    background-color: #595aff;
    text-align: center;
    padding: 26px 0;
    font-size: 34px;
    color:  white ;
    display: block;
    font-weight: 500;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 0;
}
.innerForm{
    padding: 25px;
}

.selectGender ul li{
    margin-right: 15px;

}

.selectGender ul{
    padding-left: 0;
    list-style: none;
    display: flex;
}

#proceedBtn Button{
    border-radius: 50px;

}

.modelImg{
    display: inline-block;
    position: relative;
    margin: 80px 0 0 1px;
    vertical-align:middle;
    position: relative;
    z-index: 0;
    
}
.modelImg img{
    max-width: 100%;
}

.questionFaq{
    z-index: 1;
    position: relative;
}
.searchWrapper{
    height: 45px;
}