.nav {
    display: flex;
    justify-content: space-between;

}

.nav .nav-bar {
    display: flex;
    padding: 20px 0;
    justify-content: space-evenly;
    align-items: center;


}

.nav .nav-bar .nav-list ul {
    display: flex;
    list-style: none;
    justify-content: space-around;
    align-items: center;
    margin: auto;


}

.nav .nav-bar .nav-list ul li {
    margin: 0 18px;


}

.nav .nav-bar .nav-list ul li a {
    font-weight: 500;
    display: block;
    text-decoration: none;
    font-size: 18px;
    color: black;
}

.nav .nav-bar .nav-list ul li a .login {
    color: white;
    padding: 4px 20px;
    background: blue;
    border: aliceblue;
    padding: 4px 20px;
    border-radius: 20px;
}
.price-li {
    position: relative !important;
}

.price-li .hide-price ul{
    background-color: #595aff !important;
    color: white;
    display: block !important;
    padding: 0 !important;
    width: 250px;
    border-radius: 4px;

}
.price-li .hide-price ul li{
    padding: 15px 20px;
    margin: 0 !important;
    background-color: #595aff !important;
    color: white !important;
    border-bottom: 1px solid lightgrey;
}
 .hide-price ul li a{
   text-decoration: none;
   color: white !important;
}
.hide-price ul li a:hover{
    background-color: #ffffff !important;
    color: #595aff !important;
    border: none !important
}
.price-li .hide-price ul :hover{
    background-color: #ffffff !important;
    color: #595aff !important;
    border: 1px solid #595aff;
}
.hide-price{
    display: none;
    position: absolute;
    z-index: 10;
    border-radius: 10px;
    margin-top: 0px;
    margin-left: -20px;
    border-radius: 4px;
}

.price-li:hover .hide-price{
    display: block;
}


.login {
    color: white !important;
    padding: 4px 20px;
    background: #595aff;
    border: aliceblue;
    padding: 2px 20px;
    border-radius: 20px;
}

.signup {
    color: white !important;
    padding: 4px 20px;
    background: #00d463;
    border: aliceblue;
    padding: 2px 20px;
    border-radius: 20px;
}

.spnarrow {
    margin-top: 3px;
}

.topdiv-bar{
    background-color: #00d463;
    height: 40px;
    display: flex; 
    justify-content: flex-end;
    align-items: center;
    padding:10px 30px;
}
.topdiv-bar div select{
    padding: 4px 10px;
    border: none;
    border-radius: 4px;
}

@media screen and (max-width:600px) {
 .nav{
    display: none !important;
 }
}