.signup-top-div {
    background-image: url('https://www.influglue.com/public/frontend/images/signup_landingbg.jpg?v=1688734414');
    height: 450px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.signup-top-div div {
    color: white;
    width: 600px;
    margin-right: 50px;
    margin-top: -30px;
}

.signup-top-div div h1 {
    font-size: 60px;
    letter-spacing: 1px;
    font-weight: bolder;
    margin-bottom: 20px;
    text-shadow: 3px 3px 4px rgb(96, 88, 88);
}

.signup-top-div div h5 {
    line-height: 30px;
    font-size: 22px;
    text-shadow: 3px 2px 4px rgb(69, 62, 62);

}

.signup-maindiv {
    display: flex;
    justify-content: center;
    margin: 50px;
}

.signup-maindiv .brand-influencer {
    width: 400px;
    text-align: center;
    background-color: white;
    border: 2px solid #ffffff;
    border-radius: 6px;
    padding: 40px 10px;
    margin: 0 40px;
    box-shadow: 0.5px 0.5px 8px 0.5px rgb(214, 211, 211);
    transition: all 0.5s;
    overflow: hidden;

}

.signup-maindiv .brand-influencer:hover {
    border: 2px solid #595aff;
    transform: translate(0, 10px);

}

.signup-maindiv .brand-influencer div {
    border: 1px solid #595aff;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-maindiv .brand-influencer div img {
    width: 70px;
}

.brand-influencer h2 {
    color: #595aff;
    font-weight: bold;
    margin: 30px 0;
}

.brand-influencer p {
    font-weight: 500;
    margin-bottom: 40px;
}

.brand-influencer button {
    border-radius: 30px;
    padding: 10px 50px;
    font-size: large;
    background-color: #595aff;
}
.already-signin{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}
.already-signin a{
    font-weight: bold;
    font-size: 20px;
}

@media screen and (max-width:600px) {
    .signup-top-div {
        border-image: fill 0 linear-gradient(#0001, #000);
        height: 300px;
    }

    .signup-top-div div {
        text-align: center;
        width: 350px;
    }

    .signup-top-div div h1 {
        font-size: 28px;
    }
    .signup-maindiv {
        display: block;
        margin: 40px 2px;
    }
    .signup-maindiv .brand-influencer {
        margin: 10px;
    }
}