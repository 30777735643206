.brandblock {
    padding: 70px 0;
    margin: 0;
}

.brandblock .brandblockmainwapper {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.brandblock h2 {
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 46px;
    text-align: center;
}

.brandblock ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.brandblock ul li {
    flex: 0 0 180px;
    height: 100px;
    max-width: 100%;
    margin: 0 6px 12px 0;
    border-radius: 10px;
    border: 1px solid #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
}