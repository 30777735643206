.freAsk-box{
    background-color: #595aff;
    padding: 10px 0 60px 0;
}
.freAsk-box h1{
font-weight: bold;
color: white;
padding: 40px 0 20px 0;
text-align: center;

}
.accord-ques-div{
    width: 800px;
    margin: auto;
}
.accordion-div{
    border-radius: 8px;
    margin: 10px 0;
    padding: 5px;

}
.accord-ques{
    font-weight:bolder !important;
    color: black;
}
.accord-answr{
    padding:3px 10px;
    color: black;
    letter-spacing: 1px;
    text-align: justify;
}
.accord-answr div ul li{
    line-height: 30px;
}
@media screen and (max-width:600px) {
    .accord-ques-div{
        width: 400px;
    }
    .freAsk-box h1{
        font-size: 24px;
    }
}