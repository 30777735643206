.microInfluencer {
    position: relative;
    margin-bottom: 80px;
    overflow: hidden;
    height: 560px;
}

.microInfluencer .mainWapperMicro {
    position: static;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.microInfluencer .mainWapperMicro .heroImg {
    background: #595aff;
    position: absolute;
    top: 0;
    left: 0;
    width: 46%;
    height: 100%;
    overflow: hidden;
    border-top-right-radius: 500px;
    border-bottom-right-radius: 500px;

}

.microInfluencer .mainWapperMicro .heroImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.microInfluencer .mainWapperMicro .contentblock {
    width: 540px;
    margin: 0;
    padding: 0;
}

.microInfluencer .mainWapperMicro .contentblock h2 {
    font-size: 40px;
    font-weight: 900;
    line-height: 48px;
    margin-bottom: 30px;
}

.microInfluencer .mainWapperMicro .contentblock p {
    font-size: 22px;
    font-weight: 500;
    color: black;
    line-height: 32px;
    margin-bottom: 40px;
}

.microInfluencer .mainWapperMicro .contentblock a {
    background: #595aff;
    width: 270px;
    height: 50px;
    border-radius: 50px;
    color: white;
    border: 2px solid #595aff;
    font-size: 22px;
    font-weight: 700;
    display: block;
    text-align: center;
    line-height: 50px;
    transition: all 0.5s;
    text-decoration: none;
}

@media screen and (max-width:600px) {
    .microInfluencer .mainWapperMicro {
        display: block !important;
        padding: 5px;
        position: relative;
        justify-content: center;

    }

    .microInfluencer .mainWapperMicro .heroImg {
        width: 380px;
        height: 200px;
        border-radius: 5px;
        position: unset;
        margin-left: 20px;
    }

    .microInfluencer .mainWapperMicro .contentblock {
        width: 400px;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    .microInfluencer .mainWapperMicro .contentblock h2 {
        font-size: 28px;
        line-height: 30px;
        margin-top: 30px;
    }

    .microInfluencer .mainWapperMicro .contentblock p {
        font-size: 20px;
        line-height: 30px;
    }
    .microInfluencer .mainWapperMicro .contentblock a {
        border-radius: 30px;
        font-size: 20px;
        line-height: 40px;
        margin-left: 60px;
    }
    

}