.Campaign-text {
    background: #f9f9f9;
    margin-bottom: 30px;
}

.Campaign-text .div-campaign {
    display: block;
    position: relative;
}

.Campaign-text h2 {
    text-align: center;
    color: var(--black);
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
    margin: 30px 0;
}

.Campaign-text p {
    font-size: 16px;
    color: var(--black);
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    margin: 46px 70px;
}

.leftcampdiv {
    background-color: #f9fdff;
    border-right: 1px solid #e8e9eb;
    max-width: 300px;
    margin: 30px 0;
}

.filtercampdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f3f5;
    padding: 10px 20px;
}

.filtercampdiv img {
    width: 20px;
    height: 20px;
}

.category-div,
.city-campdiv {
    font-weight: bold;
    margin: 10px;
}

.category-div h5,
.city-campdiv h5 {
    margin: 10px 0;
}

.category-div ul,
.city-campdiv ul {
    line-height: 25px;
}

.category-div ul li,
.city-campdiv ul li {
    font-size: 14px;
}
.campmainbox{
    display: flex;
    justify-content: space-evenly;
}
.rightcampdiv {
    width: 700px;
}

.right-camp-ad-box {
    margin: 30px 0;
    box-shadow: 1px 1px 4px rgb(187, 201, 223);
    padding: 20px;
    border-radius: 8px;
}

.right-camp-ad-box .product-promotion {
    display: flex;
    justify-content: space-between;
}

.right-camp-ad-box .product-promotion h4 {
    font-weight: bold;
}
.right-camp-ad-box .product-promotion h4 a{
    text-decoration: none;
    color: rgb(33, 32, 32);
}

.right-camp-ad-box .product-promotion div {
    color: grey;
    font-size: 14px;
}

.right-camp-ad-box .prod-by ul {
    display: flex;
    justify-content: space-between;
    padding-left: 1px;
    margin-left: 1px;
    font-size: 14px;
}

.right-camp-ad-box .prod-by ul li {
    display: flex;
    list-style: none;
    align-items: center;
}

.right-camp-ad-box .prod-by ul li img {
    width: 20px;
    height: 20px;
    margin: 0 8px;
}

.right-camp-ad-box .prod-by ul li .calander-icon {
    width: 18px;
    height: 18px;
    margin: 0 8px;
    color: skyblue;
}
.right-camp-ad-box .prod-by ul .brand-manage{
    background-color: #ff7b24;
    color: white;
    border-radius: 4px;
    padding:2px 8px;
}
.needinflu-div{
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    margin: 20px 10px;
    padding: 15px 0;
}
.product-detail-box ul li {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    vertical-align: baseline;
    line-height: 30px;
}
.product-detail-box ul li strong{
    flex: 0 0 200px;
}
.product-detail-box ul li small{
    flex: 0 0 40px;
}
.product-detail-box ul li span{
    width: 500px;
}

.camp-btn {
    display: flex;
    margin-left: 20px;
}

.camp-btn .btn1 {
    background-color: #595aff;
    color: white;
    border-radius: 30px;
    padding: 8px 30px;
    border: 1px solid #595aff;
    font-size: small;
    font-weight: 600;
    margin: 15px 10px;

}

.camp-btn .btn1:hover {
    background-color: white;
    color: #595aff;
    border: 1px solid #595aff;
    border-radius: 30px;

}
.camp-btn .btn2 {
    background-color: white;
    color: #595aff;
    border-radius: 30px;
    padding: 8px 30px;
    border: 1px solid #595aff;
    font-size: small;
    font-weight: 600;
    margin: 15px 10px;

}

.camp-btn .btn2:hover {
    background-color: #595aff;
    color: white;
    border: 1px solid #595aff;
    border-radius: 30px;

}

.camp-notice{
    font-family:italic;
    margin: 10px;
}

@media screen and (max-width:600px) {
    .Campaign-text h2 {
        font-size: 32px;
        padding-top: 20px;
    }
    .Campaign-text p{
        margin: 20px;
    }
    .rightcampdiv{
        width: 400px;
    }
    .campmainbox{
        display: block !important;

    }
    .leftcampdiv{
        margin: 10px 0 0 60px;
    }
    .right-camp-ad-box{
        margin: 10px 0 0 10px;

    }
    .right-camp-ad-box .product-promotion {
        display: block;
    }
    .right-camp-ad-box .prod-by ul {
        display: block;
    }
    .right-camp-ad-box .prod-by ul li {
        margin: 10px 0;
    }
    .right-camp-ad-box .prod-by ul .brand-manage{
        padding: 3px 10px;
        width: 150px;
        font-weight: bold;
    }
    
}