.notsureblock {
    padding: 65px 0;
    margin: 0;
}

.notsureblock .mainwapperNotsure {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.notsureblock h2 {
    font-size: 40px;
    font-weight: 900;
    color: black;
    text-align: center;
    margin-bottom: 50px;
}

.notsureblock .innerblocksecnotsure {
    display: flex;
    justify-content: center;
    align-items: center;
}

.notsureblock .innerblocksecnotsure .photoholder {
    width: 668px;
    height: 650px;
    border-radius: 30px;
    overflow: hidden;
}

.notsureblock .innerblocksecnotsure .photoholder img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.notsureblock .innerblocksecnotsure .contactformHoldrer {
    flex: 0 0 400px;
    max-width: 100%;
    background: white;
    border-radius: 30px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .15);
    position: relative;
    margin-left: -100px;
}

.notsureblock .innerblocksecnotsure .contactformHoldrer h2 {
    background: #595aff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 25px 30px;
    font-size: 28px;
    color: #fff;
    font-weight: 900;
    text-align: left;
    margin: 0;
}

.notsureblock .innerblocksecnotsure .contactformHoldrer h2 span {
    display: block;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    padding-top: 10px;
}

.notsureblock .innerblocksecnotsure .contactformHoldrer .innernotsure {
    padding: 30px;
    margin: 0;
}

.notsureblock .innerblocksecnotsure .contactformHoldrer .innernotsure .inputHolder {
    margin-bottom: 10px;
    display: block;
}

.notsureblock .innerblocksecnotsure .contactformHoldrer .innernotsure .inputHolder input {
    width: 100%;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #d6d6d6;
    padding: 0 18px;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
}

.notsureblock .innerblocksecnotsure .contactformHoldrer .innernotsure .inputHolder textarea {
    width: 100%;
    height: 160px;
    border-radius: 10px;
    border: 1px solid #d6d6d6;
    padding: 18px;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
}

.notsureblock .innerblocksecnotsure .contactformHoldrer .innernotsure .guestpostbtn {
    background: #595aff;
    width: 211px;
    height: 46px;
    border-radius: 30px;
    text-align: center;
    font-weight: 900;
    font-size: 21px;
    color: white;
    outline: none;
    border: 0;
    display: block;
    margin: 20px auto 0;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    transition: 0.5s;
    border: 2px solid #595aff;
}

.notsureblock .innerblocksecnotsure .contactformHoldrer .innernotsure .guestpostbtn:hover {
    background: white;
    color: #595aff;
    border-color: #595aff;
}