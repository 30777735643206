.advertise-box{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 60px 0;
    background-color: rgb(250, 250, 246);
}
.sign-up-text{
    width: 800px;
    text-align: center;
}
.sign-up-text h1{
    font-size: 46px;
    margin: 30px 0;
}
.sign-up-text h4{
    font-weight: 500;
    margin: 15px 0;
}
.sign-up-text p{
    width: 710px;
    letter-spacing: 1px;
    margin:0 0 40px 40px;
}
.input-area-div{
    width: 500px;
    padding: 30px;
    box-shadow: 1px 1px 4px 3px rgb(187, 201, 223);
    border-radius: 10px;
    margin: auto;
    background-color: white;
    
}
.input-area-div div{
    margin:3px 0;
}
.input-area-div div select{
   width: 440px;
   height: 56px;
   margin-top: 5px;
   padding-left: 8px;
}
.advertise-btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.advertise-btn-div button {
    background-color: #595aff;
    color: white;
    border-radius: 30px;
    padding: 10px 50px;
    border: 1px solid #595aff;
    font-size: large;
    font-weight: 600;
    margin-top: 15px;

}

.advertise-btn-div button:hover {
    background-color: white;
    color: #595aff;
    border: 1px solid #595aff;
    border-radius: 30px;

}

@media screen and (max-width:600px) {
    .sign-up-text{
        width:500px;
    }
    .sign-up-text h1{
        font-size: 34px;
        text-align: center;
    }
    .sign-up-text h4{
        margin: 20px 50px;
    }
    .sign-up-text p{
        text-align: center;
        width: 380px;
        margin-left: 70px;
    }
    .input-area-div{
        width: 380px;
    }
    .input-area-div div select{
        width: 320px;
    }
}