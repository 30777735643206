.breadcurmb {
    padding: 10px 0;
    font-size: 14px;
    color: #2a2e30;
    font-weight: 400;
}

.breadcurmb .mainwapperabout {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.breadcurmb a {
    color: #2a2e30;
    text-decoration: none;
    font-weight: 700;
    outline: none;
    text-decoration: none;
}

.breadcurmb span {
    padding: 0 4px;
}

.termblock {
    display: block;
    padding: 80px 0;
}

.termblock .mainwapperterm {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.termblock h1 {
    text-align: center;
    display: block;
    margin-bottom: 50px;
    font-size: 50px;
    color: black;
    line-height: 48px;
    font-weight: 900;
}

.termblock h4 {
    display: block;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 700;
    color: #595aff;
}

.termblock p {
    display: block;
    color: #454545;
    margin-bottom: 25px;
    text-align: left;
    padding: 0;
    line-height: 25px;
}