.footer {
    background: #191919;
    padding-top: 30px;
}

.footer .top-footer-block {
    padding-bottom: 30px;
}

.footer .top-footer-block .mainwap {
    display: flex;
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.footer .top-footer-block .mainwap .first-block {
    flex: 0 0 200px;
    max-width: 100%;
    margin-right: 20px;
    margin-left: 30px;
}

.footer h2 {
    font-weight: 500;
    color: white;
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
}

.footer ul {
    list-style: none;
    margin-left: -59px;
}

.top-footer-block .first-block ul li a {
    margin-bottom: 4px;
    list-style: none;
    color: #aaa;
    text-decoration: none;
    font-size: 15px;
    padding-left: 20px;
}

.top-footer-block .second-block {
    flex: 0 0 296px;
    max-width: 100%;
    margin-right: 64px;
}

.top-footer-block .second-block p {
    font-size: 15px;
    color: #aaa;
    line-height: 24px;
    margin-bottom: 30px;
}

.top-footer-block .second-block .block {
    margin-bottom: 30px;
}

.top-footer-block .second-block a {
    color: #aaa;
    text-decoration: none;
}

.top-footer-block .fourth-block {
    flex: 0 0 310px;
    max-width: 100%;

}

.top-footer-block .fourth-block p {
    font-size: 14px;
    color: #aaa;
    line-height: 21px;
    margin-bottom: 45px;
    font-weight: 400;
}

.inner-social {
    display: flex;
    flex-wrap: wrap;
}

.inner-social a {
    margin-right: 15px;
}

.footer-company {
    margin: 35px 0 0 0;
}

.footer-company a {
    color: #fff;
    text-decoration: none;
    outline: none;
    transition: all 0.5s;
}

.footer .mid-footer-block {
    border-top: 1px solid #2e2e2e;
    padding: 20px 0;

}

.footer .mid-footer-block .mainwapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

}

.footer .mid-footer-block .mainwapper a.fill {
    background: #595aff;
    border: 1px solid#595aff;
    font-size: 17px;
    font-weight: 700;
    color: white;
    text-align: center;
    border-radius: 30px;
    padding: 10px 30px;
    transition: all 0.5s;
    text-decoration: none;
    margin-right: 20px;

}

.footer .mid-footer-block .mainwap a {
    margin-right: 15px;
    flex: 0 0 178px;
    max-width: 100%;
    text-decoration: none;
}

.footer .mid-footer-block .mainwapper a.outline {
    background: transparent;
    border: 1px solid #595aff;
    font-size: 17px;
    font-weight: 700;
    color: white;
    text-align: center;
    border-radius: 30px;
    padding: 10px 35px;
    transition: all 0.5s;
    text-decoration: none;


}

.footer .copyright-block {
    background: black;
    padding: 15px 0;

}

.maincopyright-wapper {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between;
    display: flex;
}

.footer .copyright-block .left {
    flex: 1;
    font-size: 14px;
    color: #aaa;
}

.footer .copyright-block .left a {
    color: #aaa;
    text-decoration: none;
}

.footer .copyright-block .left span {
    padding: 0 10px;
    color: #aaa;
}
@media screen and (max-width:600px) {
    .footer{
        display: block !important;
    }
    .footer .top-footer-block .mainwap {
        display: block !important;
        padding: 5px;
    }
    .footer ul {
         display: flex;
         flex-wrap: wrap;
        margin:20px 2px;
        padding: 1px;
    }
    .top-footer-block .first-block ul li{
        width: 170px;
    }
    .top-footer-block .first-block ul li a {
        padding: 2px;
    }
    .top-footer-block .second-block {
        margin:30px;
    }
    .top-footer-block .fourth-block {
        margin: 30px;
    
    }
    .maincopyright-wapper {
        display: block;
        justify-content: center;
        padding: 10px;
    }
}