.bg-img-box{
    background-image: url('https://www.influglue.com/public/frontend/images/loginbg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.bg-box1{
    background-color: #595aff;
    height: 400px;
    padding: 20px 50px;
    color: white;
    text-align: center;
    border-radius: 6px 0 0 6px;

}
.bg-box1 h2{
    margin:20px 0 40px 0;
    font-weight: bold;    
}
.bg-box1 p{
    margin-bottom: 20px;
    font-size: 22px;
}
.bg-box1 .btn-div-box{
    display: flex;
}
.bg-box1 .btn-div-box .icon{
    width: 50px;
    height: 52px;
    padding: 0 5px;
    margin: 10px 0;
    background-color: #5bbb23;
    color: #ffffff;
    border-radius: 6px 0 0 6px;

}
.bg-box1 .btn-div-box button{
    background-color: white;
    color: black;
    padding: 14px 30px;
    font-weight: bold;
    margin: 10px 0 10px -4px;
}

.bg-box1 .btn-div-box button:hover{
    background-color: #5bbb23;
    color: white;
}
.bg-box2{
    background-color: #ffffff;
    width: 550px;
    height: 400px;
    padding: 40px;
    text-align: center;
    border-radius: 0 6px 6px 0;
}
.bg-box2 h3{
    font-weight: bold;
    margin:0px 0 40px 0;
}
.bg-box2 .icon3{
    width: 50px;
    height: 56px;
    padding: 0 5px;
    background-color: #595aff;
    color: #ffffff;
    border-radius: 6px 0 0 6px;
}

.bg-box2 div button{
    background-color: #5bbb23;
    color: rgb(255, 255, 255);
    padding: 12px 30px;
    font-size: large;
    font-weight: bold;
    border-radius: 30px;
    border: 1px solid #5bbb23;
    margin: 30px 0;
}

.bg-box2 div button:hover{
    background-color: #ffffff;
    color: #5bbb23;
    border: 1px solid #5bbb23;
}
@media screen and (max-width:600px) {
    .bg-img-box{
        display: block;
        padding: 40px 0 40px 45px;
        height: auto;
    }
    .bg-box1, .bg-box2{
        width: 320px;
        padding: 10px;
        border-radius: 0px;
    }
    .bg-box1 p{
        font-size: 18px;
    }
    .bg-box1 .btn-div-box{
        display: block;
    }

    .bg-box1 .btn-div-box button{
        background-color: white;
        color: black;
        font-size: 14px;
    }
    .bg-box2 h3{
        padding-top: 40px;
    }
    
}