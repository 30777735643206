.combopackblock {
    padding: 40px 0;


}


.combopackblock .bigt-title {
    text-align: center;
    font-size: 45px;
    font-weight: 800;
}

.titleblock {
    margin-bottom: 60px;
}

.mainpackege {
    background: #f8f6f0;
    padding: 30px 0 50px;
}

.mainpackege .mainwapguest {
    width: 100%;
    align-items: center;
}

.mainpackege .mainwapguest h5 {
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.innerGuestSection {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.innerGuestSection .block {
    margin-right: 34px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    flex: 0 0 280px;
}

.innerGuestSection .block .blue {
    background: #595aff;
}

.innerGuestSection .block .orange {
    background: #fdae2e;
}

.innerGuestSection .block .green {
    background: #00d463;
}

.innerGuestSection .block .top {
    padding: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    color: white;
}

.innerGuestSection .block .top h3 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 30px;
    margin-bottom: 5px;
}

.innerGuestSection .block .top p {
    font-size: 18px;
}

.innerGuestSection .block .mid {
    padding: 20px 10px;
    text-align: center;
    border-bottom: 1px solid #d0cec7;
}

.innerGuestSection .block small {
    /* display: table; */
    margin: 0 auto 22px;
    font-size: 20px;
    color: #a5a5a5;
    text-align: center;
    text-decoration: line-through 2px solid black;

}

.innerGuestSection .block .mid .price {
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-size: 50px;
    font-weight: 900;
    margin: 0 0 15px 0;
}

.innerGuestSection .block .mid .price span {
    font-size: 26px;
    font-weight: 500;
    color: black;
    padding: 0 5px 0 0;
}

.innerGuestSection .block .mid .saveblock {
    display: table;
    border-radius: 30px;
    color: white;
    font-size: 16px;
    font-weight: 700;
    margin: 0 auto 16px;
    padding: 2px 18px;

}

.innerGuestSection .block .mid h4 {
    text-align: center;
    font-size: 18px;
    color: black;
    font-weight: 700;
}

.innerGuestSection .block .last {
    padding: 20px 30px;
}

.innerGuestSection .block .last ul li {
    border-bottom: 1px solid #f1eee5;
    margin: 0 0 5px 0;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    padding-left: 15px;


}

.innerGuestSection .block .bottomblock {
    background: #f9f9f9;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.innerGuestSection .block .bottomblock input {
    background: black;
    width: 80%;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    outline: none;
    border: 0;
    color: #fff;
    padding: 9px 0;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    transition: all 0.5s;
    margin: 0 auto;
    display: block;

}

.difficultblock {
    background: white;
    width: 720px;
    margin: 0 auto;
    border-radius: 300px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.difficultblock .leftblock {
    padding: 28px 36px;
    flex: 1;
    max-width: 100%;
    border-right: 1px solid #e8edf1;
}

.difficultblock .leftblock h2 {
    font-size: 40px;
    font-weight: 900 !important;
    color: #00467f;
    line-height: 40px;
    margin-bottom: 8px !important;
    text-transform: uppercase;
}

.difficultblock .leftblock p {
    font-size: 16px;
    color: black;
}

.difficultblock .rightblock {
    flex: 0 0 210px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.difficultblock .rightblock a {
    border-radius: 30px;
    border: 2px solid #3745d6;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 48px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 900;
    color: #3745d6 !important;
    text-decoration: none;
    transition: 0.5s;
    cursor: pointer;

}

.difficultblock .rightblock a:hover {
    background-color: #3745d6;
    color: #e8edf1 !important;



}




/* .newcss */


.ulkg {
    margin-top: 46px;
    margin-right: -29px;
}

.howitWorkGuest .back {
    background-color: #595aff;
    width: 100%;
    height: 300px;


}

.howitWorkGuest {
    padding: 60px 0 450px 0;
    position: relative;
}

.howitWorkGuest .mainwapperhowguest {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.mainwapperhowguest .titleblockguesthow {
    margin: 0 0 40px 0;
}

.mainwapperhowguest .titleblockguesthow h2 {
    padding-top: 58px;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 14px;
    text-align: center;
    color: white;
}

.mainwapperhowguest .titleblockguesthow p {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: white;
}

.howitWorkGuest .innerhiwblock {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.howitWorkGuest .innerhiwblock .block {
    background: white;
    border-radius: 30px;
    padding: 30px 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    border: 1px solid #595aff;
    margin: 0 16px 23px 0;
    flex: 0 0 359px;
}

.howitWorkGuest .innerhiwblock .block .blocktitle {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #efeee9;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.howitWorkGuest .innerhiwblock .block .blocktitle h4 {
    text-transform: uppercase;
    font-weight: 900;
    color: #595aff;
    font-size: 28px;
}

.howitWorkGuest .innerhiwblock .block .blocktitle h4 span {
    display: block;
    font-size: 20px;
    font-weight: 500;
    text-transform: none;
    color: black;
    padding-top: 5px;
}

.howitWorkGuest .innerhiwblock .block p {
    line-height: 22px;
    font-size: 15px;
}


/* blueadd */
.blueAdblocks {
    padding: 50px 0 80px;
    background: #595aff;

}

.blueAdblocks .innerblueads {
    width: 850px;
    border-radius: 300px;
    border: 2px solid white;
    margin: 0 auto;
    padding: 30px 50px;
    text-align: center;
}

.blueAdblocks .innerblueads p {
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
}

.blueAdblocks .innerblueads h3 {
    text-align: center;
    font-size: 44px;
    font-weight: 900;
    color: white;
    line-height: 50px;
    text-shadow: 0 0 5px rgba(0, 0, 0, .15);
    margin-bottom: 30px;
}

.blueAdblocks .innerblueads a {
    background: white;
    width: 280px;
    height: 55px;
    border-radius: 100px;
    display: block;
    margin: 0 auto -60px;
    color: #595aff;
    font-size: 27px;
    font-weight: 900;
    line-height: 52px;
    position: relative;
    border: 2px solid white;
    text-decoration: none;
    transition: 0.5s;
}

.blueAdblocks .innerblueads a:hover {
    background: #595aff;
    color: white;
}

.moneyBackBlock {
    padding: 50px 0;
}

.moneyBackBlock .mainwapperMoneyBlock {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.moneybackinner h2 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 30px;
    display: block;
}

.moneybackinner ul {
    list-style: none;
    margin: 0 0 0 10px;
    padding: 0;
}

.moneybackinner ul li {
    font-size: 20px;
    position: relative;
    padding-left: 20px;
    font-weight: 400;
}