
.swiper-slide {
    /* text-align: center; */
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item {
    overflow: hidden;
    height: 600px;
    padding: 130px 0 0 0;
}

.img-itm {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.mainimgwapper {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 475px;
    display: block;
    position: relative;
}

.slider-content-block {
    margin: 0;
    padding: 0;
    width: 668px;
}

.slider-content-block span {
    color: white;
    font-size: 28px;
    font-weight: bold;

}
.slider-content-block h2 {
    font-size: 71px;
    text-shadow: 0 2px 5px rgba(0, 0, 0, .3);
    font-weight: 900;
    line-height: 80px;
    color: white;
    margin: 0 0 20px 0;
}

.slider-content-block h3 {
    font-size: 28px;
    text-shadow: 0 2px 5px rgba(0, 0, 0, .3);
    font-weight: 700;
    line-height: 36px;
    color: white;
    margin: 0 0 40px 0;
}

.slider-content-block a.bluebtn {
    width: 200px;
    border-width: 2px;
    height: 56px;
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
    text-align: center;
    line-height: 54px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    outline: none;
    cursor: pointer;
    text-decoration: none;

}

a.bluebtn {
    background: #595aff;
    border: 1px solid #595aff;
}
.swiper-pagination-clickable .swiper-pagination-bullet{
    width: 30px;
    height: 6px;
    border-radius: 4px;
}

@media screen and (max-width:600px) {
    .swiper-slide img {
        width: 400px;
        height: 400px;  
        object-fit: cover; 
    }
    .item{
        padding-top: 40px;
        height: 400px;
    }
    .item .img-itm {
        width: 400px;
        height: 400px;
        object-fit: cover; 
    }
    .mainimgwapper {
        max-width: 600px;
        width: 400px;
        padding: 5px;
    }
    
    .slider-content-block {
        width: 400px;
        text-align: center;
    }
    
    .slider-content-block span {
        font-size: 18px;
    }
    .slider-content-block h2 {
        font-size: 28px;
        margin:0 0 5px 0;
    line-height: 34px;

    }
    
    .slider-content-block h3 {
        font-size: 18px;
        margin:20px 0;
        line-height: 30px;
    }
    a.bluebtn {
        margin:30px 0 0 90px;
    }
    
}