.OurWorkSlider {
    padding: 60px 0;
}

.mainWpOurwork {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.OurWorkSlider h2 {
    text-align: center;
    font-weight: 900;
    color: black;
    margin-bottom: 40px;
    font-size: 40px;
}

.clientWorkblock {
    margin: 0 10px;
    padding: 0;
    border: 1px solid #1755A6;
    border-radius: 10px;
}

.clientWorkblock .logoholder {
    height: 90px;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.clientWorkblock .logoholder img {
    width: auto !important;
}

.clientWorkblock .logoTitle {
    background: #595aff;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    color: #fff;
    padding: 15px 0;
}

.clientWorkblock .infoimg-folder {
    /* height: 256px; */
    overflow: hidden;
}

.clientWorkblock .infoimg-folder img {
    display: block;
    width: 100%;
}

.abarisnfluencer {
    padding: 0;
    margin: 0;
    outline: none;
    border: 0;
    display: flex;
    list-style: none;
}

.borderRight {
    border-right: 1px solid #c4c4c4;
}

.abarisnfluencer li {
    /* border: 2px solid black; */
    background: transparent;
    flex: 1;
    max-width: 100%;
    /* border: 0; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 15px 10px;
    /* box-shadow: 0; */
    border-radius: 0;
    font-size: 30px;
    font-weight: 800;
    line-height: 24px;
}

.spnabaris {
    font-weight: 500;
    color: #000;
    font-size: 14px;
}

.startbtnrt {
    background: #595aff;
    color: #fff;
    display: block;
    margin: 30px auto;
    border-color: #595aff;
    width: 283px;
    height: 54px;
    border-radius: 30px;
    font-size: 27px;
    font-weight: 900;
    text-align: center;
    display: block;
    line-height: 53px;
    /* color: #3334db; */
    border: 1px solid #fff;
    transition: all 0.5s;
    text-decoration: none;
}

.startbtnrt:hover {
    background-color: #fff;
    border-color: #595aff;
    color: black;
}