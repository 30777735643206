.camp-detail-box {
    background: #f9f9f9;
    margin-bottom: 30px;
}

.camp-detail-box .divbox {
    display: block;
    position: relative;
    padding: 80px 0;
}

.camp-detail-box h1 {
    text-align: center;
    font-size: 46px;
    line-height: 40px;
    font-weight: 900;
}
.leftcampd-div{
    display: flex;
    justify-content: space-evenly;
}

.similar-div h4{
background-color: #e9eff4;
padding: 20px;
margin-top: 30px;
font-weight: 700;
color: rgb(31, 31, 31);
margin-bottom: 20px;
}
.similar-div p{
    font-weight: bold;
    margin-left: 5px;
}
.overview-task{
    padding: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgray;
}
.overview-task h5{
    font-weight: bold;
}
.overview-task p{
    font-size: 18px;
}

@media screen and (max-width:600px) {
    .camp-detail-box h1 {
        font-size: 28px;
    }
    .leftcampd-div{
        width: 400px;
        display: block;
        margin-left: 15px;
    }
}