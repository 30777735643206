.price-text {
    background: #f9f9f9;
    /* padding: 70px 0 55px; */
    margin-bottom: 30px;
}

.price-text .div-price {
    display: block;
    position: relative;
}

.price-text h2 {
    text-align: center;
    font-size: 42px;
    line-height: 40px;
    font-weight: 900;
    margin: 30px 0;
    letter-spacing: 1px;
}

.price-div {
    /* margin: auto; */
    /* width: 900px; */
    display: flex;
    justify-content: center;
    padding: 20px 0px 10px 0;
}

.price-tab1,
.price-tab2 {
    padding: 10px 25px;
    background-color: #ffffff;
    border: none;
    margin: 0 0 3px 0px;
    font-weight: bold;
    color: #00467f;
    text-align: start;
    font-size: 20px;
}

.price-tab1 {
    border: 2px solid#00467f;
    border-radius: 30px 0 0 30px;
}

.price-tab2 {
    border: 2px solid#00467f;
    border-radius: 0 30px 30px 0;
}

.price-tab1:hover,
.price-tab2:hover {
    border: 2px solid#00467f;
    background-color: #00467f;
    color: #ffffff;
}

.price-tabpanel {
    background-color: #F8F6EC;
    /* height: 500px; */
    margin-top: 30px;
    padding: 10px 0 70px 0;
}

.packages-plans {
    display: flex;
    justify-content: space-evenly;
    margin: 50px 0;
}

.nano-package {
    width: 300px;
    border: 1px solid lightgrey;
    border-radius: 20px;
    box-shadow: 1px 1px 4px 4px lightgrey;
    background-color: white;
}

.package-name-nano,
.package-name-micro,
.package-name-macro,
.package-name-mega {
    /* fw-bold text-center mb-4 */
    font-weight: bold;
    text-align: center;
    color: white;
    padding: 15px 1px;
    border-radius: 12px;
    margin: 10px;
    font-size: 22px;
}

.package-name-nano {
    background-color: #fdae2e;
}

.package-name-micro {
    background-color: #14b8a6;
}

.package-name-macro {
    background-color: #595aff;
}

.package-name-mega {
    background-color: #00d463;
}

.nano-package .nano-type ul {
    font-weight: bold;
}

.nano-package .nano-type ul li {
    margin: 10px 20px 0 0;
    padding: 20px 0;
    font-size: 18px;
    border-bottom: 1px solid lightgrey;
}

.nano-package .nano-type ul li p {
    margin-bottom: 3px;
}

.nano-package .nano-type ul li span {
    font-weight: 400;
}

.nano-package .nano-type ul li select {
    width: 210px;
    padding: 10px 20px;
    border-radius: 30px;
}

.deliver-nano {
    padding-bottom: 150px;
    border-bottom: 1px solid grey;
}

.nano-package .deliver-nano h6 {
    font-weight: bold;
    margin: 20px 0 0 30px;


}

.nano-package .deliver-nano ul {
    margin-top: 10px;
    padding-left: 18px;
}

.btn-div .gst-p {
    margin: 10px 30px;
    font-style: italic;
}

.btn-div button {
    margin: 30px 0;
    padding: 10px 40px;
    border-radius: 30px;
    font-weight: bold;
}


.choose-plan {
    display: flex;
    background-color: white;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 100px;
    width: 900px;
    height: 200px;
    padding: 10px 20px;
    margin: auto;
    box-shadow: 1px 1px 4px 3px lightgrey;
}

.choose-plan .div1 {
    width: 500px;
}

.div1 h1 {
    color: #00467f;
    font-weight: bold;
    margin-bottom: 10px;
}

.div2 button {
    border-radius: 30px;
    padding: 12px 30px;
    border: 2px solid #3745d6;
    font-size: large;
    font-weight: 600;
    background-color: white;
    color: #3745d6;
}

.div2 button:hover {
    background-color: #3745d6;
    color: white;
    border: 2px solid #3745d6;
    border-radius: 30px;

}

.how-we-work {
    margin: 50px;
}

.how-we-work h1 {
    font-weight: bold;
}

.how-we-work ul {
    line-height: 35px;
    font-size: 18px;
}

.how-we-work ul li {
    display: flex;
    align-items: center;
    font-weight: 400;

}

.how-we-work ul li:before {
    content: "•";
    font-size: 220%;
    padding: 0 10px 5px 0;
    color: #595aff;
}

.manage-plan {
    background-color: white;
    text-align: center;
    align-items: center;
    border-radius: 180px;
    width: 800px;
    height: 300px;
    padding: 30px 20px;
    margin: auto;
    box-shadow: 1px 1px 4px 3px lightgrey;
}

.manage-plan div p {
    width: 650px;
    margin: auto;
    padding: 20px 0;
    font-size: 18px;
    font-weight: 500;
}

.type-infulencer {
    margin-top: 70px;
    padding: 70px 0;
}

.type-infulencer .back {
    background-color: #595aff;
    width: 100%;
    height: 350px;


}

.type-infulencer h2 {
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: 600;
    text-shadow: 0 2px 5px rgba(0, 0, 0, .2);
    margin: 0 0 20px 0;
    line-height: 60px;
    padding: 50px 0 0 0;
}


.type-infulencer ul {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    margin-bottom: 45px;
}

.type-infulencer ul li {
    background: white;
    border-radius: 30px;
    border: 1px solid #595aff;
    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    text-align: center;

}

.type-infulencer ul li h3 {
    font-weight: 700;
    font-size: 27px;
    color: black;
    line-height: 27px;
    margin: 30px 0 30px 0;
    width: 200px;
    line-height: 35px;
}

.type-infulencer ul li h3 span {
    font-weight: 400;
}

.type-infulencer ul li p {
    text-align: center;
    font-size: 15px;
    color: #545454;
    padding: 0;
    margin: 0;
    line-height: 20px;
}
.maindivInfluncermarketing {
    padding-top: 60px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}
.ulk {
    margin-top: -170px;
}

.type-infulencer ul li div p {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}

.type-infulencer ul li div span {
    font-size: 18px;
}

.type-infulencer ul li img {
    width: 80px;
    height: 80px;
}

.infulencerdiv-tag {
    margin-bottom: 40px;
}

.infulencerdiv-tag h3 {
    font-weight: bold;
    margin: 20px 0;
}

.infulencerdiv-tag p {
    text-align: justify;
    letter-spacing: 1px;
    margin: 0 40px 0 0;
    font-size: 17px;
}

@media screen and (max-width:600px) {
    .price-text h2 {
        font-size: 26px;
    }

    .price-tab1,
    .price-tab2 {
        padding: 6px 18px;
        font-size: 16px;

    }

    .price-tab1 {
        border: 2px solid#00467f;
        border-radius: 10px 0 0 10px;
    }

    .price-tab2 {
        border: 2px solid#00467f;
        border-radius: 0 10px 10px 0;
    }

    .packages-plans {
        display: block;
        margin-left: 30px;
    }

    .nano-package {
        width: 360px;
        margin: 20px 0;
    }

    .choose-plan {
        display: block;
        width: 400px;
        height: 250px;
        border-radius: 10px;
        padding: 30px 10px;

    }

    .choose-plan .div1 {
        width: 400px;
        text-align: center;
    }

    .div1 h1 {
        font-size: 24px;
    }

    .div1 p {
        font-size: 18px;
        margin: 10px 20px;
    }

    .choose-plan .div2 {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .div2 button {
        padding: 6px 20px;
        font-size: medium;
    }

    .how-we-work {
        margin: 20px;
    }

    .how-we-work h1 {
        text-align: center;
        font-size: 24px;
    }

    .how-we-work ul {
        padding: 0 10px;
        line-height: 28px;
        text-align: justify;
        font-size: 16px;
    }

    .how-we-work ul li {
        margin: 20px 0;
        align-items: start;
    }

    .manage-plan {
        display: block;
        width: 400px;
        height: 280px;
        border-radius: 10px;
        padding: 30px 10px;
        margin-top: 50px;
    }

    .manage-plan .div1 {
        width: 400px;
        text-align: center;
    }

    .manage-plan div p {
        width: 350px;
        font-size: 16px;
    }
    .type-infulencer ul{ 
        overflow: scroll;
        width: 400px;
    }
    .type-infulencer ul li{ 
        margin-left: 20px;
    }

}