@media only screen and (min-width: 1600px) {
    .bookHolderBanner img{
        max-height:600px
    }

    .bookHolderBanner .content_box {
        width: 650px
    }

    .bookHolderBanner .content_box h1 {
        font-size: 48px
    }

    .bookHolderBanner .content_box h2 {
        line-height: 31px;
        font-size: 20px
    }
}






@media only screen and (min-width: 1920px) {
    .bookHolderBanner img{
        background-size:cover!important;
        background-repeat: no-repeat!important;
        background-position: center center!important;
        max-height: 600px
    }

    .bookHolderBanner .content_box {
        background-color: rgba(255,255,255,.502);
        padding: 40px;
        border-radius: 20px;
        width: 700px;
        margin-left: auto
    }

    .bookHolderBanner .content_box h1 {
        font-size: 55px;
        color: var(--black);
        line-height: 68px;
        font-weight: 500;
        margin-bottom: 20px
    }

    .bookHolderBanner .content_box h1 span {
        text-transform: uppercase;
        color: var(--blue);
        font-weight: 900
    }

    .bookHolderBanner .content_box h2 {
        font-size: 21px;
        font-weight: 500;
        color: var(--black);
        line-height: 30px
    }
}


.bookHolderBanner .bookBannerContent {
    padding: 150px 0;
    display: flex;
    position: relative;
}

.bookHolderBanner .content_box {
    background-color: rgba(255,255,255,.502);
    padding: 40px;
    border-radius: 20px;
    width: 700px;
    margin-left: auto
}

.bookHolderBanner .content_box h1 {
    font-size: 45px;
    color: black;
    line-height: 58px;
    font-weight: 500;
    margin-bottom: 20px
}

.bookHolderBanner .content_box h1 span {
    text-transform: uppercase;
    color: blue;
    font-weight: 900
}

.bookHolderBanner .content_box h2 {
    font-size: 18px;
    font-weight: 500;
    color: black;
    line-height: 28px
}

.bookHolderBanner img{
    width: 100%;
    position: absolute;
}



/* section 22222 */



.inner_block {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.inner_block .content_sec {
    flex: 0 0 530px;
    max-width: 100%
}

.inner_block .content_sec h3 {
    font-size: 48px;
    color: black;
    line-height: 62px;
    font-weight: 500;
    margin-bottom: 36px
}

.inner_block .content_sec p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    color: black;
    line-height: 26px;
    text-align: justify
}

.inner_block .content_sec p:not(:last-child) {
    margin-bottom: 30px
}

.inner_block .image_content_sec {
    flex: 0 0 500px;
    max-width: 100%;
    margin-right: -50px;
    position: relative
}

.inner_block .image_content_sec .inner_imgblock {
    background: #00d463;
    width: 100%;
    height: 540px;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px
}

.inner_block .image_content_sec .inner_imgblock img,.thumbnail_block img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.thumbnail_block {
    position: relative;
    left: -70px;
    bottom: -645px;
    width: 240px;
    height: 270px;
    overflow: hidden;
    border: 3px solid rgba(255,255,255,.493);
    box-shadow: 1.854px 5.706px 27px 3px rgba(55,56,204,.39);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px
}


.cat_about_block {
    padding: 0px 0 130px;
    border-bottom: 2px dashed var(--gray);
}




/* 3333333 section */

.home_service_block {
    background: #fafafa;
    padding: 70px 0
}

.home_service_block .searvice_block {
    padding: 0;
    margin: 0
}

.home_service_block .searvice_block h2 {
    color: black;
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
    margin-bottom: 20px
}

.home_service_block .searvice_block p {
    width: auto;
    font-size: 20px;
    color: black;
    font-weight: 400;
    padding: 0 200px;
    line-height: 30px
}

.searvice_block {
    padding: 100px 0
}

.searvice_block h2 {
    text-align: center;
    color:black;
    font-size: 48px;
    font-weight: 500;
    margin-bottom: 40px
}

.searvice_block p {
    text-align: center;
    width: 50%;
    margin: 0 auto 46px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500
}

.searvice_block .inner_service_block {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    flex-wrap: wrap !important;
}

.searvice_block .inner_service_block .block:not(:last-child) {
    margin-right: 24px
}

.searvice_block .inner_service_block .block {
    flex: 1;
    max-width: 100%
}

.searvice_block .inner_service_block .block a {
    background: white;
    text-decoration: none;
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid blue;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    border-radius: 30px;
    color: var(--nav-gray);
    position: relative;
    overflow: hidden
}

.searvice_block .inner_service_block .block a::before {
    
    background: blue;
    position: absolute;
    left: 0;
    top: -150px;
    width: 100%;
    height: 100px;
    border-bottom-left-radius: 500px;
    border-bottom-right-radius: 500px;
    content: "";
    transition: all 0.5s
}

.searvice_block .inner_service_block .block a:hover {
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    transform: translateY(10px)
}

.searvice_block .inner_service_block .block a:hover .iconblock {
    box-shadow: 0 2px 10px rgba(0,0,0,.1)
}

.searvice_block .inner_service_block .block a:hover::before {
    top: 0
}

.searvice_block .inner_service_block .block .iconblock {
    background: white;
    border-radius: 100%;
    display: flex;
    width: 90px;
    height: 90px;
    margin: 0 auto 10px;
    position: relative;
    z-index: 1;
    transition: all 0.5s;
    overflow: hidden
}

.searvice_block .inner_service_block .block .iconblock img {
    margin: auto;
    max-width: 100%;
    width: 70px;
    height: 70px
}

.searvice_block .inner_service_block .block h4 {
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-top: auto;
    padding: 0 5px;
    color: black;
}
  /* section 444444444444
   */


   .bottom_two_gray_block {
    background: #f9f9f9;
    padding: 100px 0
}

.bottom_two_gray_block .inner_content_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.bottom_two_gray_block .inner_content_block .how_it_holder {
    flex: 0 0 480px;
    max-width: 100%;
}

.bottom_two_gray_block .inner_content_block .how_it_holder h3 {
    margin-bottom: 50px;
    font-size: 35px;
    font-weight: 500;
    color: black;
}

.bottom_two_gray_block .inner_content_block .how_it_holder ul.inner_block {
    display: flex;
    flex-direction: column;
    counter-reset: my-awesome-counter
}

.bottom_two_gray_block .inner_content_block .how_it_holder ul.inner_block li {
    counter-increment: my-awesome-counter;
    display: flex;
    align-items: center
}

.bottom_two_gray_block .inner_content_block .how_it_holder ul.inner_block li .inner {
    background: #fff;
    font-size: 17px;
    padding: 15px;
    margin-left: 15px;
    line-height: 25px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: .854px 2.706px 10px 3px rgba(55,56,204,.05)
}

.bottom_two_gray_block .inner_content_block .how_it_holder ul.inner_block li::before {
    background: #595aff;
    flex: 0 0 39px;
    max-width: 100%;
    height: 39px;
    display: block;
    content: counter(my-awesome-counter);
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    line-height: 39px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%
}

.bottom_two_gray_block .inner_content_block .how_it_holder ul.inner_block li:not(:last-child) {
    margin-bottom: 20px
}

.bottom_two_gray_block .inner_content_block .lead_capture_form {
    background: white;
    border: 1px solid blue;
    flex: 0 0 500px;
    max-width: 100%;
    box-shadow: .927px -2.853px 37.6px 2.4px rgba(164,164,213,.4);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px
}

/* .bottom_two_gray_block .inner_content_block .lead_capture_form .signup_frm h2 {
    background: blue;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 700;
    color: white;
    padding: 30px;
    line-height: 36px;
    position: relative
}

.bottom_two_gray_block .inner_content_block .lead_capture_form .signup_frm h2::before {
    position: absolute;
    left: 30px;
    bottom: -12px;
    border-top: 30px solid blue;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    content: ""
} */

.bottom_two_gray_block .inner_content_block .lead_capture_form .signup_frm .inner {
    padding: 36px 36px 25px 36px
}

/* .bottom_two_gray_block .inner_content_block .lead_capture_form .signup_frm .inner .input_holder input[type="submit"],.bottom_two_gray_block .inner_content_block .lead_capture_form .signup_frm .inner .input_holder input[type="button"] {
    margin-top: 25px;
    text-transform: uppercase;
    font-weight: 700
} */

.bottom_two_gray_block a.greenbtn {
    margin-top: 80px;
    display: table;
    width: auto;
    margin: 80px auto 0;
    padding: 0 25px;
    font-size: 24px;
    font-weight: 700;
    text-transform: none;
    height: 65px;
    line-height: 65px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
   
    color: #fff;
    text-decoration: none;
}


.greenbtn{
    background-color: #00d463;
    border: 1px solid #00d463;
}
a.greenbtn:hover {
    color: #00d463;
    background-color: #fff;
}

