.banner-section img{
    position: absolute;
    z-index: 0;
}

.headerContainer{
    position: relative;
    z-index: 1;
    padding: 160px 0px;
    display: flex;
    justify-content: flex-end;
    
}

#headerCol{
    padding-right: 120px;
    padding-top: 18px;
}


.header-heading h2{
    font-size: 60px;
    line-height: 70px;
    color: white;
    font-weight: 900;
    margin-bottom: 20px;
}
.header-para p{
    padding: 0px;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: white;

}

#form-section {
   display: flex;
   justify-content: center;
   /* padding-bottom: 30px; */
   
}

.form{
    width: 722px;
    background-color: white;
    border-radius: 6px;
    margin:0px  50px 50px 50px;
    position: relative;
    top: -45px;
}

.formHeading h2{
    background-color: blue;
    text-align: center;
    padding:  26px 0px;
    color: white;
    font-size: 34px;
    font-weight: 500;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 76px;
   
}

.inner-formHeading{
    padding: 30px;
}


.inputsForms {
   margin-bottom: 13px;
}

.inputsForms Button {
    border-radius: 50px;
    width: 198px;
    height: 54px;
    font-size: 20px;
    text-align: center;
   line-height: 54px;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 #Submit_btn{
    display: flex;
    justify-content: center;
 }


 .contact-info-block{
    width: 270px;
    height: 52px;
    padding: 10px;
    background-color: white;
    border-radius: 6px;
 }

 .contactInfoCol,  .contactInfoPara {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
 }

 .contactInfoPara{
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
    font-weight: 500;
 }

 .contactInfoAdd {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    font-weight: 500;
 }

 .contactInfoAdd span{
    padding-right: 10px;
 }








