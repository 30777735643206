/* .topserviceBanner {
    margin: 0;
    position: relative;
    overflow: hidden;
    padding: 0 0 58px 0;
    margin-bottom: 20px;
}

.topserviceBanner .mainwapperTopServiceContent {
    position: static;
}

.mainwapperTopServiceContent {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
}

.topserviceBanner .mainwapperTopServiceContent ::before {
    background: #595aff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 90%;
    content: "";
}

.heroimhBlockCVontent {
    width: 692px;
    height: 556px;
    position: absolute;
    top: 0;
    right: -100px;
}

.heroimhBlockCVontent ::before {
    background: url(https://www.influglue.com/public/frontend/images/influncer/dots1.png) no-repeat 0 0;
    background-size: contain;
    position: absolute;
    left: -68px;
    bottom: 26px;
    width: 94px;
    height: 150px;
    content: "";
    z-index: 1;
}

.heroimhBlockCVontent .innercontent {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-bottom-left-radius: 50px;
    z-index: 2;
    position: relative;
}

.topserviceBanner .mainwapperTopServiceContent .bannerContent-marketing {
    width: 596px;
    margin: 0;
    padding: 80px 0 50px;
    position: relative;
}

.topserviceBanner .mainwapperTopServiceContent .bannerContent-marketing h1 {
    font-size: 49px;
    font-weight: 900;
    color: white;
    text-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    line-height: 52px;
    margin-bottom: 24px;
}

.topserviceBanner .mainwapperTopServiceContent .bannerContent-marketing p {
    font-size: 16px;
    line-height: 24px;
    color: white;
    margin: 0 0 50px 0;
} */
.topserviceBanner {
    padding-bottom: 60px;
}

.banneContent {
    background: #595aff;
    height: 483px;
    /* padding: 18px 199px; */
    padding-top: 39px;

    padding-left: 106px;
    padding-right: 167px;

}

.bannerh1content {
    font-size: 49px;
    font-weight: 900;
    color: white;
    text-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    line-height: 52px;
    margin-bottom: 24px;
}

.paracontentmark {
    font-size: 16px;
    line-height: 24px;
    color: white;
    margin: 0 0 50px 0;
}

.contentMarketingHeroImg img {
    width: 100%;
    border-bottom-left-radius: 50px
}

.startbtnc {
    background-color: #fff;
    width: 283px;
    height: 54px;
    border-radius: 30px;
    font-size: 27px;
    font-weight: 900;
    text-align: center;
    display: block;
    line-height: 53px;
    color: #3334db;
    border: 1px solid #fff;
    transition: all 0.5s;
    text-decoration: none;
}

.startbtnc:hover {
    background-color: #595aff;
    color: #fff;
}



.whatPromotionBlock {
    margin: 0;
    padding: 0 0 60px 0;
}

.mainWapperPromotionBlock {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.whatPromotionBlock .innerPromotion {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.whatPromotionBlock .innerPromotion .photoblockPromotion {
    width: 496px;
    margin-right: 20px;
    position: relative;
}

.whatPromotionBlock .innerPromotion .photoblockPromotion .secondblock {
    width: 180px;
    height: 180px;
    border-radius: 25px;
    overflow: hidden;
    position: absolute;
    bottom: -50px;
    right: 20px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, .3);
}

.whatPromotionBlock .innerPromotion .photoblockPromotion .firstblockp {
    background: #ccc;
    width: 448px;
    height: 344px;
    border-radius: 30px;
    overflow: hidden;
}

.whatPromotionBlock .innerPromotion .congtent {
    flex: 1;
    max-width: 100%;
}

.whatPromotionBlock .innerPromotion .congtent h2 {
    text-align: left;
    font-weight: 900;
    font-size: 40px;
    line-height: 42px;
    margin-bottom: 30px;
}

.whatPromotionBlock .innerPromotion .congtent p {
    font-size: 18px;
    line-height: 27px;
}

.bli {
    background: #595aff;
    position: absolute;
    left: -11px;
    top: -11px;
    width: 118px;
    height: 118px;
    border-radius: 30px;
    content: "";
    z-index: -1;
}

.hi {
    background: url("https://www.influglue.com/public/frontend/images/influncer/dots1.png") no-repeat 0 0;
    background-size: contain;
    position: absolute;
    left: 779px;
    bottom: -93px;
    width: 94px;
    height: 150px;
    content: "";
    z-index: 1;
}

/* why chose */
.whyChose {
    padding: 60px 0;
    margin: 0;
}

.mainwapperWhyChose {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.whyChose h2 {
    text-align: center;
    font-weight: 900;
    color: black;
    margin-bottom: 40px;
    font-size: 40px;
}

.whyChose p {
    margin: 0 0 25px 0;
    font-size: 16px;
    line-height: 24px;
}

.whyChose ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
}

.whyChose ul li {
    flex: 0 0 266px;
    border-radius: 100px;
    border: 1px solid #3745d6;
    padding: 17px 17px 17px 58px;
    margin: 0 15px 20px 0;
    font-size: 19px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    position: relative;
    height: 80px;
}

.whyChose ul li .ber {
    background: url("https://www.influglue.com/public/frontend/images/newicon/tick.png") no-repeat;
    width: 36px;
    height: 36px;
    position: absolute;
    left: 12px;
    top: 19px;
    content: "";
}

/* howdoesitwork */
.howDoesitWork {
    background: #f9f9f9;
    padding: 60px 0;
}

.mainwapperHowDoes {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.howDoesitWork h2 {
    text-align: center;
    font-weight: 900;
    color: black;
    margin-bottom: 40px;
    font-size: 40px;

}

.howDoesitWork .innerHowDoes {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
}

.howDoesitWork .innerHowDoes .block {
    margin-right: 75px;
    flex: 0 0 320px;
    background: white;
    border-radius: 30px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    position: relative;
}

.howDoesitWork .innerHowDoes .block h3 {
    background: #5556f4;
    padding: 20px 10px;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.howDoesitWork .innerHowDoes .block .innerblockHowDoes {
    padding: 20px;
}

.howDoesitWork .innerHowDoes .block .innerblockHowDoes p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.howDoesitWork .innerHowDoes .block .arrowafter {
    background: url("https://www.influglue.com/public/frontend/images/newicon/marrow.png") no-repeat;
    width: 48px;
    height: 30px;
    position: absolute;
    top: 98px;
    right: -63px;
    content: "";
}

.knowMore {
    position: relative;
    margin-bottom: 80px;
    height: 560px;
}

.knowMore .mainWapperKnowmore {
    position: static;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.contentKnowmore {
    width: 565px;
    margin: 0;
    padding: 0;

}

.contentKnowmore h2 {
    font-size: 40px;
    font-weight: 900;
    line-height: 48px;
    margin-bottom: 30px;

}

.contentKnowmore p {
    font-size: 16px;
    font-weight: 400;
    color: black;
    line-height: 25px;
    margin-bottom: 25px;
}

a.startbtn123 {
    background: #595aff;
    width: 213px;
    color: white;
    height: 54px;
    border-radius: 30px;
    font-size: 27px;
    font-weight: 900;
    text-align: center;
    display: block;
    line-height: 53px;
    color: #fff;
    border: 1px solid #fff;
    transition: all 0.5s;
    text-decoration: none;
}

.heroimgKnowmore {
    background: #595aff;
    position: absolute;
    top: 0;
    right: 0;
    width: 46%;
    height: 560px;
    overflow: hidden;
    border-top-left-radius: 500px;
    border-bottom-left-radius: 500px;
}

.heroimgKnowmore img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}