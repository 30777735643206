.homeserviceBlock {
    background: #fafafa;
    padding: 70px 0;
}

.homeserviceBlock .mainHomeservice {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.homeserviceBlock .serviceblock {
    padding: 0;
    margin: 0;
}

.homeserviceBlock .serviceblock h2 {
    color: black;
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
    margin-bottom: 20px;
}

.serviceblock h2 {
    text-align: center;
}

.homeserviceBlock .serviceblock p {
    width: auto;
    font-size: 20px;
    color: black;
    font-weight: 400;
    padding: 0 200px;
    line-height: 30px;
    text-align: center;
    margin: 0 auto 46px;
}

.serviceblock .inner-service-block {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
}

.serviceblock .inner-service-block .block {
    margin:10px 24px 10px 0;
    flex: 1;
    max-width: 100%
}

.serviceblock .inner-service-block .block a {
    background: white;
    text-decoration: none;
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid#a9a9fe;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    border-radius: 30px;
    color: #545454;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
}
.serviceblock .inner-service-block .block a:hover {
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    transform: translateY(10px);
}

.serviceblock .inner-service-block .block .iconblock {
    background: white;
    border-radius: 100%;
    display: flex;
    width: 90px;
    height: 90px;
    margin: 0 auto 10px;
    position: relative;
    z-index: 1;
    transition: all 0.5s;
    overflow: hidden;
}

.serviceblock .inner-service-block .block .iconblock img {
    margin: auto;
    max-width: 100%;
    width: 70px;
    height: 70px
}

.serviceblock .inner-service-block .block h6 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: auto;
    padding: 0 5px;
    color: black;
}

@media screen and (max-width:600px) {
    .homeserviceBlock .serviceblock h2 {
        font-size: 30px;
    }
    .homeserviceBlock .serviceblock p {
        padding: 0 30px;
    }
    .serviceblock .inner-service-block .block {
       margin: 8px;
    }
}