.blueSteps {
    padding: 60px;
    margin: 0;
    position: relative;
    border-bottom: 1px solid #e5e5e5;
}

.blueSteps .bluebghalf {
    background: #595aff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 350px;
    content: "";
}

.mainWapperBluesteps {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.blueSteps h2 {
    text-align: center;
    font-weight: 900;
    color: white;
    margin-bottom: 48px;
    font-size: 40px;
}

.blueSteps .innerBlueStep {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.blueSteps .innerBlueStep .block {
    margin-right: 13px;
    background: white;
    flex: 1;
    border-radius: 30px;
    border: 1px solid #595aff;
    padding: 48px 22px 20px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
}

.blueSteps .innerBlueStep .block span {
    background: #595aff;
    position: absolute;
    left: 9px;
    top: 9px;
    width: 41px;
    height: 41px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
    color: white;
}

.blueSteps .innerBlueStep .block img {
    margin: 0 auto 24px;
    display: block;
}

.blueSteps .innerBlueStep .block p {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
}

.startbtngh {
    background: #595aff;
    color: #fff;
    display: block;
    margin: 0 auto;
    border-color: #595aff;
    width: 283px;
    height: 54px;
    border-radius: 30px;
    font-size: 27px;
    font-weight: 900;
    text-align: center;
    line-height: 53px;
    border: 1px solid #fff;
    transition: all 0.5s;
    text-decoration: none;
}

.startbtngh:hover {
    background: #fff;
    color: black;
    border-color: #595aff;
}