.bodycontent_holder {
    padding: 20px 0 40px;
}

.main_wrapper {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative
}
.details_main_block iframe {
    display: block!important;
    margin: 0 auto 25px!important;
}


.allpost_bottom_holder {
    display: flex;
    justify-content: space-between;
}

.allpost_bottom_holder .left_allpost_block {
    flex: 0 0 70%;
    margin-right: 30px;

}

.allpost_bottom_holder .left_allpost_block h2 {
    display: block;
    font-size: 20px;
    text-transform: uppercase;
    color: black;
    margin-bottom: 20px;
    font-weight: 700
}

.allpost_bottom_holder .left_allpost_block h2 a {
    color: blue;
    text-decoration: none;
}

.allpost_bottom_holder .left_allpost_block h2 a:hover {
    color: black;
    text-decoration: none
}

.allpost_bottom_holder .left_allpost_block .blocktwo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px
}

.allpost_bottom_holder .left_allpost_block .blocktwo .dropdown_block {
    height: 34px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    position: relative
}

.allpost_bottom_holder .left_allpost_block .blocktwo .dropdown_block::after {
    content: "";
    position: absolute;
    top: 15px;
    right: 10px;
    border-top: 6px solid #fff;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    pointer-events: none
}

.allpost_bottom_holder .left_allpost_block .blocktwo .dropdown_block select {
    background: blue;
    width: 100%;
    height: 100%;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    outline: none;
    border: 0;
    font-size: 14px;
    padding: 0 30px 0 20px;
    color: white;
    cursor: pointer
}

.table_of_content {
    background: #f1f1f1;
    padding: 2rem;
    margin-bottom: 20px
}

.table_of_content ul {
    margin-bottom: 0
}

.table_of_content ul li a {
    color: black;
    text-decoration: underline
}

.table_of_content ul li a:hover {
    color:black;
    text-decoration: none
}

.table_of_content p{
    margin-left: 15px;
    margin-bottom: 8px !important;
}
.table_of_content ul{
    margin-bottom: 15px !important;
    padding-left: 0;
}
.leadcapture_red_vertical_block{
   background-color: #f67766;
    margin: 0 auto 40px;
    padding: 25px;
    border-radius: 6px;
}
.leadcapture_red_vertical_block h2{
    color: white !important;
    text-shadow: 0 2px 2px rgba(0,0,0,.2);
    text-align: center;
    font-size: 34px !important;
 }

 .leadcapture_red_vertical_block a{
    background-color: #fff;
    display: table;
    margin: auto;
    text-align: center;
    padding: 10px 20px;
    border-radius: 30px;
    border: 1px solid white;
    font-size: 22px;
    width: 35%;
    font-weight: 700;
    color: #ef513c;
    
 }

.textbanner.blue a{ 
    text-decoration: none;
 }
 .textbanner.green a{ 
    text-decoration: none;
 }
 

 



.allpost_bottom_holder .home_sidebar {
    flex: 0 0 300px;
    width: 26%;
}

.allpost_bottom_holder .home_sidebar .block h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: uppercase
}

.allpost_bottom_holder .home_sidebar .block:not(:last-child) {
    margin-bottom: 40px
}

.ebook_sidebar_block .block:not(:last-child) {
    margin-bottom: 10px!important;
}


.allpost_bottom_holder .home_sidebar .block .homepost_list {
    margin-bottom: 20px
}

.allpost_bottom_holder .home_sidebar .block .homepost_list .block {
    background: transparent;
}

.allpost_bottom_holder .home_sidebar .block .homepost_list .block:not(:last-child) {
    border-bottom: 1px solid #eceff1;
    padding: 0 0 15px 0;
    margin: 0 0 15px 0
}

.allpost_bottom_holder .home_sidebar .block .homepost_list .block .textdetails {
    padding: 0 0 0 8px
}

.allpost_bottom_holder .home_sidebar .block .homepost_list .block .textdetails h3 {
    margin-bottom: 5px
}

.allinner_postblock {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap
}

.allpost_bottom_holder .home_sidebar .block a.outline {
    padding: 5px 12px
}

.allinner_postblock .block {
    flex: 0 0 31%;
    margin: 0 27px 30px 0
}

.allinner_postblock .block a {
    text-decoration: none
}

.allinner_postblock .block:nth-child(3n) {
    margin-right: 0px!important
}

.allinner_postblock .block .img_holder {
        /* background: url(https://www.influglue.com/public/frontend/images/preloader.gif);
        background-repeat: no-repeat; */
    background-size: cover;
    height: 190px;
    overflow: hidden;
    margin-bottom: 15px;
    border: 1px solid #f1f1f1;
    border-radius: 4px
}

.allinner_postblock .block .img_holder img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.allinner_postblock .block h2 {
    text-transform: none;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 10px
}

.allinner_postblock .block .time {
    font-size: 14px;
    color: #322626;    
}

ul.catlist {
    display: block;
    padding-left: 0;
}

ul.catlist li {
    list-style: none;
    position: relative;
    padding-left: 15px
}

ul.catlist li::after {
    background: black;
    width: 6px;
    height: 6px;
    position: absolute;
    left: 0;
    top: 14px;
    content: ""
}

ul.catlist li:not(:last-child) {
    border-bottom: 1px solid #f1f1f1;
}


ul.catlist li a {
    color: black;
    text-decoration: none;
    padding: 5px 0;
    display: block;
    font-weight: 600
}

.ebook_sidebar_block .block{
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
}
.ebook_sidebar_block .block a, .ebook_sidebar_block a {
   text-decoration: none;
}
.ebook_sidebar_block .block a .cover_block {
    
    margin: 0 0 10px 0;
}
.ebook_sidebar_block .block a .content_block {
    display: flex;
    align-items: center;
}
.ebook_sidebar_block .block a .content_block h3 {
    flex: 1;
    margin-right: 20px;
    text-align: left;
    color: black;
    text-decoration: none;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
}

.ebook_sidebar_block .block a .content_block span, .ebook_listing_block .eblock_holder .content_block span {
    border-radius: 4px;
    background:#00d463;
    font-size: 14px;
    color: white;
    text-decoration: none;
    padding: 4px 10px;
    font-weight: 700;
}

.ebook_sidebar_block a.view_all {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    border-radius: 30px;
    display: table;
    margin: 0 auto;
    padding: 4px 15px;
    background: #595aff;
    color: white;
}

/* .pagination_holder {
    display: table;
    margin: 50px auto
}

.pagination_holder ul {
    text-align: center;
    display: block
}

.pagination_holder ul li {
    display: inline-block;
    vertical-align: middle
}

.pagination_holder ul li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    color: #4f5c63;
    font-weight: 700;
    text-decoration: none
}

.pagination_holder ul li a:hover,.pagination_holder ul li a.active {
    background-color: blue;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: white;
}

.pagination_holder ul li a.pre,.pagination_holder ul li a.next {
    background: gray;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    color: white;
    position: relative;
}

.pagination_holder ul li a.pre {
    margin-right: 30px
}

.pagination_holder ul li a.next {
    margin-left: 30px
}

.pagination_holder ul li a.pre:hover,.pagination_holder ul li a.next:hover {
    background: blue;
}



.allpost_bottom_holder .left_allpost_block h1 {
    display: block;
    font-size: 35px;
    line-height: 45px;
    color: black;
    margin-bottom: 15px;
    font-weight: 700
}

.allpost_bottom_holder .left_allpost_block .date {
    margin-bottom: 25px
}

.category_inner_block {
    display: flex;
    flex-direction: column
}

.category_inner_block .block:not(:last-child) {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid lightgray;
}

.category_inner_block .block {
    display: flex;
    flex-direction: row
}

.category_inner_block .block .imgblock {
    background: url("https://www.influglue.com/public/frontend/images/preloader.gif");
    background-repeat: no-repeat;
    background-size: cover;
    width: 245px;
    height: 190px;
    margin-right: 30px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    overflow: hidden;
    position: relative
}

.category_inner_block .block .imgblock img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.category_inner_block .block .imgblock .author_block {
    position: absolute;
    left: 10px;
    bottom: 10px;
    display: flex;
    align-content: center;
    align-items: center
}

.category_inner_block .block .imgblock .author_block span {
    color: white;
    font-size: 14px;
    font-weight: 700;
    text-shadow: 0 0 4px rgba(0,0,0,.6)
}

.category_inner_block .block .imgblock .author_holder {
    background: url("https://www.influglue.com/public/frontend/images/preloader.gif") var(--white);
    background-repeat: no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    overflow: hidden;
    border: 1px solid lightgray;
    margin-right: 5px
}

.category_inner_block .block .imgblock img {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px
}

.category_inner_block .block .textdetails {
    flex: 1
}

.category_inner_block .block .textdetails h3 {
    font-size: 20px;
    font-weight: 700;
    display: block;
    line-height: 30px;
    margin-bottom: 10px
}

.category_inner_block .block .textdetails p {
    line-height: 24px;
    margin-bottom: 10px;
    font-size: 17px
}

.category_inner_block .block .textdetails .time {
    display: flex;
    justify-content: space-between
}

.category_inner_block .block .textdetails .time span {
    font-size: 14px
}

.category_inner_block .block .textdetails .time span a {
    color:#322626;
    text-decoration: none;
    font-weight: 400
}

.category_inner_block .block .textdetails .time span a:not(:last-child) {
    margin-right: 5px
}

.details_main_block blockquote {
    margin: 0 auto 25px!important;
    display: block!important
}

.details_main_block hr {
    outline: none;
    border: 0;
    margin-bottom: 50px;
    margin-top: 50px;
    display: block;
    height: 1px;
    background: #f1f1f1
}

.details_main_block table {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0,0,0,.08);
    margin: 0 0 25px 0;
    padding: 0;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px
}

.details_main_block table tr:first-child td {
    font-weight: 700;
    text-align: center;
    font-size: 20px
}

.details_main_block table tr:first-child td:first-child {
    background: lightgray;
    color: white;
}

.details_main_block table tr:first-child td:last-child {
    background: #FF3855;
    color: white;
}

.details_main_block table tr td {
    padding: 0;
    padding: 10px 15px;
    font-weight: 500;
    width: 50%
}

.details_main_block {
    display: block
}

.details_main_block table tr:nth-child(even) {
    background: #f1f1f1
}

.details_main_block a.bluebtn {
    display: table;
    width: auto;
    padding: 0 25px;
    margin-bottom: 25px
}

.details_main_block p a.bluebtn {
    color: white !important;
    text-decoration: none!important
}

.details_main_block p a.bluebtn:hover {
    color: gray!important;
    text-decoration: none!important
}

.details_main_block .stage {
    background: url("https://www.influglue.com/public/frontend/images/preloader.gif");
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    margin-bottom: 30px
}

.details_main_block .stage img {
    width: 100%;
    object-fit: cover
}

.details_main_block .stage .pptslider_outer {
    display: block;
    overflow: hidden
}

.owl-nav {
    background: rgba(0,0,0,.6);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: 0px!important;
    position: relative;
    z-index: 99;
    top: -50px
}

.owl-nav::after {
    clear: both;
    display: table;
    content: ""
}

.owl-prev {
    float: left
}

.owl-next {
    float: right
}

.owl-prev,.owl-next {
    border-radius: 50%!important;
    -webkit-border-radius: 50%!important;
    -moz-border-radius: 50%!important;
    -ms-border-radius: 50%!important;
    -o-border-radius: 50%!important;
    width: 40px;
    height: 40px;
    font-size: 0px!important;
    position: relative
}

.owl-prev::before {
    position: absolute;
    left: 13px;
    top: 10px;
    content: "";
    border-top: 3px solid black;
    border-left: 3px solid black;
    transform: rotate(-45deg);
    width: 20px;
    height: 20px;
    opacity: 10
}

.owl-next::after {
    position: absolute;
    left: 5px;
    top: 10px;
    content: "";
    border-top: 3px solid black;
    border-left: 3px solid black;
    transform: rotate(135deg);
    width: 20px;
    height: 20px;
    opacity: 10;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg)
}

.details_main_block p a {
    color:blue;
    text-decoration: none;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    font-weight: 700
}

.details_main_block p a:hover {
    color: #191b1f;
    text-decoration: underline;
    font-weight: 700
}

.details_main_block p {
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 25px
}

.details_main_block p img {
    margin: 0 auto;
    display: block
}

.details_main_block p iframe {
    margin: 0 auto;
    display: block;
    width: 80%;
    height: 450px
}

.details_main_block p .left {
    padding: 10px 30px 10px 0;
    clear: both
}

.details_main_block p .right {
    padding: 10px 0 10px 30px;
    clear: both
}

.details_main_block h2,.details_main_block h3 {
    text-transform: none!important;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px!important
}

.details_main_block h3 a {
    color:#595aff;
    text-decoration: none
}

.details_main_block h3 a:hover {
    color: black;
    text-decoration: none
}

.details_main_block ul.dots,.details_main_block ul {
    display: block;
    margin-bottom: 40px;
    padding-left: 0;
}


.details_main_block ul.dots li:not(:last-child),.details_main_block ul li:not(:last-child) {
    margin-bottom: 10px
}

.details_main_block ul.dots li,.details_main_block ul li {
    position: relative;
    padding-left: 18px;
    font-size: 17px;
    line-height: 24px;
    list-style: none
}

.details_main_block ul.dots li::after,.details_main_block ul li::after {
    background: #191b1f;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    content: "";
    left: 0;
    top: 8px
}

.details_main_block ul li a,.details_main_block ul.dots li a {
    font-weight: 600;
    text-decoration: none;
    color: blue;
}

.details_main_block ul li a:hover,.details_main_block ul.dots li a:hover {
    font-weight: 700;
    text-decoration: underline;
    color:#191b1f;
}

.highlited_box {
    padding: 26px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    color: #000;
    border: 1px solid #b3b3b3;
    margin-bottom: 25px
}

.lead_capture_one {
    background: #7E57C2
}

.lead_capture_two {
    background: #2E7D32
}

.lead_capture_one,.lead_capture_two {
    display: block;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    box-shadow: 0 0 30px rgba(0,0,0,.1);
    margin: 0 auto 40px;
    padding: 30px 20px
}

.lead_capture_one h4,.lead_capture_two h4 {
    text-align: center;
    margin-bottom: 30px;
    color: white;
    display: block;
    font-size: 24px
}

.lead_capture_one h3,.lead_capture_two h3 {
    text-align: center;
    margin-bottom: 15px!important;
    color: #fff800;
    display: block;
    font-size: 34px!important;
    line-height: 40px;
    text-shadow: 0 2px 2px rgba(0,0,0,.2)
}

.lead_capture_one p,.lead_capture_two p {
    text-align: center;
    font-size: 18px;
    display: block;
    margin-bottom: 25px;
    line-height: 22px;
    color: white;
}

.lead_capture_one a {
    color: #7E57C2
}

.lead_capture_two a {
    color: #2E7D32
}

.lead_capture_one a,.lead_capture_two a {
    background: white;
    display: table;
    margin: 0 auto;
    text-align: center;
    padding: 10px 20px;
    border-radius: 30px;
    border: 1px solid white;
    font-size: 22px;
    width: auto;
    font-weight: 700
}

.lead_capture_one a:hover,.lead_capture_two a:hover {
    background: transparent;
    color:white
} */

.share_block_bottom {
    background: blue;
    margin-top: 30px;
    border-radius: 10px;
    overflow: hidden
}

.share_block_bottom .top {
    padding: 30px 30px 20px 30px;
    color: white;
    border-bottom: 1px solid #fff
}

.share_block_bottom .top h2 {
    color:white;
    font-size: 28px!important;
    font-weight: 900;
    margin: 0 0 10px 0!important;
    padding: 0
}

.share_block_bottom .top p {
    line-height: 22px!important;
    margin: 0!important;
    padding: 0!important
}

.share_block_bottom .share_block {
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.share_block_bottom .share_block h3 {
    margin: 0!important;
    color: white;
    font-size: 22px!important
}

.share_block_bottom .share_block .social_share {
    display: flex;
    flex-wrap: wrap
}

.share_block_bottom .share_block .social_share a {
    background:blue;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s
}

.share_block_bottom .share_block .social_share a:hover {
    box-shadow: 0 5px 10px rgba(0,0,0,.2)
}

.share_block_bottom .share_block .social_share a:not(:last-child) {
    margin-right: 10px
}

 .author_bio_holder {
    margin-top: 50px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    padding: 25px;
    display: flex
}

.author_bio_holder .picholder {
    
    width: 150px;
    height: 150px;
    margin-right: 20px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    
}

.author_bio_holder .detailsbock {
    flex: 1
}

.author_bio_holder .detailsbock h3 {
    font-size: 15px;
    margin-bottom: 10px!important;
    display: block
}

.author_bio_holder .detailsbock p {
    font-size: 16px;
    line-height: 22px;
    color: black;
    margin: 0
}

.category_holder {
    display: block;
    margin-top: 20px
}

.category_holder .inner:not(:last-child) {
    margin-bottom: 15px
}

.category_holder span {
    display: inline-block;
    font-weight: 700;
    margin-right: 10px
}

.category_holder a {
    font-size: 14px;
    color: white;
    text-decoration: none;
    display: inline-block;
    padding: 2px 10px;
    background: #595aff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: 500
}

.category_holder a:hover {
    text-decoration: none;
    background:gray;
    color: blue
}

.related_post_holder {
    margin-top: 50px
}

.related_post_holder h3 {
    font-size: 24px;
    margin-bottom: 20px!important
}

.related_post_holder .innerpost_block {
    display: flex
}

.related_post_holder .innerpost_block .block {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    width: 32%
}
.related_post_holder .innerpost_block .block a {
    text-decoration: none;
    
    
}

.related_post_holder .innerpost_block .block:not(:last-child) {
    margin-right: 30px
}

.related_post_holder .innerpost_block .block .img_holder {
    background: url("https://www.influglue.com/public/frontend/images/preloader.gif");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 200px;
    overflow: hidden
}

.related_post_holder .innerpost_block .block .img_holder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.related_post_holder .innerpost_block .block .titleholder {
    padding: 15px;
    color: #333;
    font-size: 20px;
    text-decoration: none;
    font-size: 17px;
    font-weight: 700
}
.bottom_ads {
    display: table;
    margin: 0 auto 40px
}




.textbanner.blue {
    background: #595aff
}

.textbanner.blue span {
    color: #595aff
}

.textbanner.green {
    background: #00ce60
}

.textbanner.green span {
    color: #00ce60
}

.textbanner.red {
    background-image: linear-gradient(to right,#ef513c,#f67766)
}

.textbanner.red span {
    color: #ef513c
}

.textbanner.lightgreen {
    background-image: linear-gradient(to right,#2bd677,#0dbd5f)
}

.textbanner.lightgreen span {
    color: #0dbd5f
}

.textbanner {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 15px
}

.textbanner h2 {
    text-align: center;
    display: block;
    font-size: 30px!important;
    line-height: 34px;
    color: white;
    position: relative;
    padding-top: 15px;
    margin: 0;
    font-weight: 900!important;
    text-transform: none!important
}

.textbanner hr {
    display: block;
    width: 80px;
    height: 2px;
    background: white;
    outline: none;
    border: 0;
    margin: 20px auto
}

.textbanner p {
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: white;
    margin-bottom: 20px
}

.textbanner span {
    background: white;
    display: block;
    width: 100%;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    text-align: center;
    padding: 10px 0;
    font-size: 22px;
    font-weight: 700
}

.searchblock {
    display: block;
    overflow: hidden
}

.searchblock input[type="text"] {
    background: white;
    width: 70%;
    height: 40px;
    padding: 0 10px;
    color: black;
    outline: none;
    border: 0;
    font-size: 14px;
    float: left;
    border: 1px solid #191b1f
}

.searchblock input[type="submit"],.searchblock input[type="button"] {
    background: #191b1f;
    width: 26%;
    height: 40px;
    padding: 0 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color:white;
    outline: none;
    border: 0;
    font-size: 14px;
    text-transform: uppercase;
    float: right;
    cursor: pointer
}

.about_block,.terms_block {
    display: block;
    padding: 80px 0
}

.about_block h1,.terms_block h1 {
    text-align: center;
    display: block;
    margin-bottom: 50px;
    font-size: 50px;
    color: black;
    line-height: 48px;
    font-weight: 900
}

.about_block h1 span,.terms_block h1 span {
    display: block;
    color: #868e96!important;
    font-size: 22px;
    margin-top: 25px
}

.about_block p {
    display: block;
    color:gray;
    margin-bottom: 50px;
    text-align: left;
    padding: 0 100px;
    line-height: 25px
}

.terms_block h3 {
    display: block;
    font-size: 20px;
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid gray;
}

.terms_block h4 {
    display: block;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 700;
    color: blue
}

.terms_block p {
    display: block;
    color:gray;
    margin-bottom: 25px;
    text-align: left;
    padding: 0;
    line-height: 25px
}

.terms_block ul {
    margin-bottom: 15px
}

.terms_block ul li {
    font-size: 16px;
    color: gray;
    position: relative;
    padding-left: 20px
}

.terms_block ul li::after {
    background:blue;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: absolute;
    left: 0;
    top: 6px;
    content: ""
}

.terms_block ul li:not(:last-child) {
    margin-bottom: 8px
}