.aboutJobsBlock {
    display: block;
    padding: 80px 0;
}

.aboutJobsBlock .JobsMainWapper {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    position: relative;
}

.aboutJobsBlock h1 {
    text-align: center;
    display: block;
    margin-bottom: 50px;
    font-size: 50px;
    color: black;
    line-height: 48px;
    font-weight: 900;
}

.aboutJobsBlock h1 span {
    display: block;
    color: #868e96 !important;
    font-size: 22px;
    margin-top: 25px;
}

.jobsBlock .block {
    margin-bottom: 20px;
    padding: 20px 25px;
    background: white;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .08);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, .06);


}

.jobsBlock .block .leftblock {
    flex: 1;
    max-width: 100%;
    margin-right: 40px;
    display: flex;
    flex-direction: column;


}

.jobsBlock .block .leftblock h3 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 17px;


}

.jobsBlock .block .leftblock p {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    color: #626970;


}

.jobsBlock .block .leftblock ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.jobsBlock .block .leftblock ul li {
    margin-right: 8px;
    background-color: #868e96;
    font-weight: 600;
    white-space: nowrap;
    font-size: 13px;
    color: #fff;
    border-radius: 30px;
    padding: 2px 8px;
    line-height: 18px;
}

.jobsBlock .block a.bluebtn {
    flex: 0 0 110px;
    max-width: 100%;
    font-size: 14px;
    height: 39px;
    line-height: 39px;
    width: 100%;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
    text-align: center;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    text-decoration: none;
}