.advblock {
    padding: 80px 0;
    display: block;
}

.advblock h2 {
    text-align: center;
    color: #3b373a;
    display: block;
    margin-bottom: 40px;
    font-size: 50px;
    line-height: 50px;
}

.advblock .twoblock {
    display: flex;
}

.advblock .twoblock .block:first-child {
    background-color: #595aff;
}

.advblock .twoblock .block {
    flex: 0 0 50%;
    padding: 80px 100px;
}

.advblock .twoblock .block h3 {
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    font-size: 25px;
    display: block;
    margin-bottom: 50px;
}

.textpoint {
    display: flex;
    flex-direction: column;
}

.textpointlist {
    margin-bottom: 40px;
    display: flex;
    color: white;
    align-items: center;
}

.iconholder {
    margin-right: 30px;
    flex: 0 0 64px;
}

.textHolder {
    flex: 1;
}

.textHolder h4 {
    font-size: 25px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
}

.textHolder p {
    line-height: 22px;
    display: block;
    font-size: 20px;
}